















import { Component, Prop, Vue } from 'vue-property-decorator'

import { ILink } from '@/store/types'

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ required: true })
  private links!: ILink[]
}
