
























import Component, { mixins } from 'vue-class-component'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import UserMixin from '@/mixins/UserMixin'
// store
import interviewModule from '@/store/modules/interview'
import commonStore from '@/store/modules/common'
// components
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
// interfaces
import { IInterviewLink, IOprosLink } from '@/store/types/'

@Component({
  components: {
    ButtonWithPlus,
    NotFoundMessage,
  },
})
export default class InterviewContent extends mixins(NotifyMixin, UserMixin) {

  private interview: IInterviewLink | null = null
  private opros: IOprosLink | '' = ''

  private metaInfo(): MetaInfo {
    return {
      title: 'Опросы',
    }
  }

  private mounted() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Опросы', path: this.$route.path },
      ])
    }, 10)

    if (!interviewModule.interview) {
      interviewModule.fetchInterview()
        .then((response) => {
          this.interview = response
        })
        .catch((err) => {
          this.notifyError(err)
        })
    } else {
      this.interview = interviewModule.interview
    }

    if (!interviewModule.oprosLink) {
      interviewModule.fetchOpros()
        .then((response) => {
          this.opros = response
        })
        .catch((err) => {
          this.notifyError(err)
        })
    } else {
      this.opros = interviewModule.oprosLink
    }
  }
}
