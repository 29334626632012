import { RouteConfig } from 'vue-router'

// page
import Projects from '@/views/projects/index.vue'
// subpages
import ProjectsEdit from '@/views/projects/edit/index.vue'
import ProjectsItems from '@/views/projects/list/index.vue'
// middleware
import auth from '@/router/_middleware/auth'
import admin from '@/router/_middleware/admin'

const route: RouteConfig[] = [
  {
    component: ProjectsEdit,
    name: 'projects.main.item',
    path: '/projects/:projectID(\\d+)',
    meta: { middleware: [auth, admin] },
  },
  {
    component: ProjectsEdit,
    name: 'projects.add',
    path: '/projects/add',
    meta: { middleware: [auth, admin] },
  },
  {
    component: ProjectsEdit,
    name: 'projects.region.add',
    path: '/projects/regions-add/:regionID/:projectID(\\d+)',
    meta: { middleware: [auth] },
  },
  {
    component: ProjectsEdit,
    name: 'projects.region.edit',
    path: '/projects/regions-edit/:projectID(\\d+)',
    meta: { middleware: [auth] },
  },
  {
    children: [
      {
        component: ProjectsItems,
        name: 'projects.main',
        path: 'main',
        meta: { middleware: [auth, admin] },
      },
      {
        component: ProjectsItems,
        name: 'projects.region',
        path: 'regions',
        meta: { middleware: [auth] },
      },
    ],
    component: Projects,
    name: 'projects',
    path: '/projects',
    redirect: '/projects/main',
  },
]

export default route
