import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/utils/services/api'
import store from '@/store'
import { IInterviewLink, IOprosLink } from '@/store/types'


@Module({
  dynamic: true,
  name: 'regions',
  store,
})
class Interview extends VuexModule {
  // ------------------------------ COMMON ------------------------------ >>
  interview: IInterviewLink | null = null
  oprosLink: IOprosLink | '' = ''

  @Mutation
  setInterviewData(payload: IInterviewLink) {
    this.interview = payload
  }

  @Action({ rawError: true })
  async fetchInterview(): Promise<IInterviewLink> {
    const { data } = await api.get(
      '/polls/results',
    )

    this.setInterviewData(data)

    return data
  }

  @Mutation
  setOprosData(payload: IOprosLink) {
    this.oprosLink = payload
  }

  @Action({ rawError: true })
  async fetchOpros(): Promise<IOprosLink> {
    const { data } = await api.get(
      '/test/results',
    )

    this.setOprosData(data)

    return data
  }
}

const interviewModule = getModule(Interview)

export default interviewModule
