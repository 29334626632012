<template lang="pug">
  .main.pt-8
    RouterView(class="wrapper__main")
</template>
<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Events extends Vue {
  
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  max-width: 1120px;
  margin: 0 32px;
}

.wrapper__main {
  height: 100%;
  padding-bottom: 64px;
}
</style>