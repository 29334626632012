import { Component } from 'vue-property-decorator'

// store
import regionModule from '@/store/modules/regions'

// mixins
import NotifyMixin from './NotifyMixin'

@Component
export default class RegionMixin extends NotifyMixin {
  protected get regions() {
    return [{ id: 0, name: 'Федеральный уровень' }, ...regionModule?.regions]
  }

  protected created() {
    if (!regionModule?.regions?.length) {
      regionModule.fetchRegions()
        .catch((err) => this.notifyError(err))
    }
  }
}

