



























import { Component, Vue } from 'vue-property-decorator'

/**
 * Модалка подтверждения действия
 *
 * Пример использования:
 *
 * В шаблоне:
 * <Confirmation ref="confirm"></Confirmation>
 *
 * В компоненте:
 * @Ref() confirm: Confirmation
 *
 * private confirmDelete () {
 *   this.confirm.open(
 *     'Удаление сущности',
 *     'Вы уверены, что хотите удалить сущность?',
 *     {
 *       buttonConfirmText: 'Удалить',
 *     },
 *   )
 *     .then(this.handleDelete)
 *     .catch(() => (this.someAction)
 * }
 */

interface ConfirmOptions {
  buttonCancelText?: string,
  buttonCancelVisible?: boolean,
  buttonConfirmText?: string,
  buttonConfirmVisible?: boolean,
  width?: number,
}

@Component
export default class ShowPassword extends Vue {
  private visible = false
  private resolve: any = null
  private reject: any = null
  private password = ''
  private options: ConfirmOptions = {
    buttonCancelText: 'Отмена',
    buttonCancelVisible: true,
    buttonConfirmText: 'Да',
    buttonConfirmVisible: true,
    width: 400,
  }

  public open (password: string, options?: ConfirmOptions) {
    this.visible = true
    this.password = password
    this.options = Object.assign(this.options, options)

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  private handleConfirm () {
    this.password = ''
    this.resolve(true)
    this.visible = false
  }

  private handleCancel () {
    this.password = ''
    this.reject()
    this.visible = false
  }
}
