import { RouteConfig } from 'vue-router'

// page
import Home from '@/views/home/index.vue'
// middleware
import auth from '@/router/_middleware/auth'
import admin from '@/router/_middleware/admin'

const route: RouteConfig[] = [{
  component: Home,
  name: 'home',
  path: '/main',
  meta: { middleware: [auth, admin] },
}]

export default route
