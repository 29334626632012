var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.form)?_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('div',{staticClass:"wrapper__event-form"},[_c('div',{staticClass:"content"},[_c('VForm',[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"rule":[],"placeholder":"Название","label":"Название","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}})]}}],null,false,564315781)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"label":"Дата","rule":[],"invalid":errors.length > 0,"error":errors[0],"range":true},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})]}}],null,false,750561769)}),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{staticClass:"mx-6",attrs:{"options":_vm.regions,"disabled":!_vm.isAdmin,"placeholder":"Регион","label":"Регион","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.regionId),callback:function ($$v) {_vm.$set(_vm.form, "regionId", $$v)},expression:"form.regionId"}})]}}],null,false,2944501437)}),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.projects,"placeholder":"Проект","label":"Проект","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.projectId),callback:function ($$v) {_vm.$set(_vm.form, "projectId", $$v)},expression:"form.projectId"}})]}}],null,false,3151820929)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"date"},[_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TimePicker',{attrs:{"label":"C","placeholder":"C","rule":[],"invalid":errors.length > 0,"error":errors[0],"disabled":!_vm.dateRange.length},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}})]}}],null,false,1426207380)})],1),_c('div',{staticClass:"date mx-6"},[_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TimePicker',{attrs:{"label":"До","placeholder":"До","rule":[],"invalid":errors.length > 0,"error":errors[0],"disabled":!_vm.dateRange.length},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}})]}}],null,false,2651686971)})],1),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Место","label":"Место","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.place),callback:function ($$v) {_vm.$set(_vm.form, "place", $$v)},expression:"form.place"}})]}}],null,false,1842909648)})],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"file-uploader mr-6"},[_c('FileUploader',{attrs:{"label":"Обложка","initialFileName":_vm.coverFileName,"rules":"image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.coverId),callback:function ($$v) {_vm.$set(_vm.form, "coverId", $$v)},expression:"form.coverId"}})],1),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Ссылка на трансляцию","label":"Ссылка на трансляцию","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.videoLink),callback:function ($$v) {_vm.$set(_vm.form, "videoLink", $$v)},expression:"form.videoLink"}})]}}],null,false,3848787332)})],1),_c('div',{staticClass:"tiptap-container mb-12"},[_c('TiptapVuetify',{staticClass:"tiptap",attrs:{"extensions":_vm.extensions,"placeholder":"Содержание","label":"Содержание","height":"280px","no-resize":""},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('ValidationProvider',{staticClass:"tiptap__input",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"","label":"","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})]}}],null,false,79022958)})],1),_c('div',{staticClass:"row mb-6"},[_c('div',{staticClass:"file-uploader mr-6"},[_c('FileUploader',{attrs:{"placeholder":"Программа","label":"Программа","initialFileName":_vm.schemeFileName,"rules":"ext:pdf|size:51200","message":"Размер файла не более 50 Мб. *.pdf"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.schemeId),callback:function ($$v) {_vm.$set(_vm.form, "schemeId", $$v)},expression:"form.schemeId"}})],1)]),_vm._l((_vm.form.speakers),function(speaker,index){return _c('div',{key:index,staticClass:"speaker-block"},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"ФИО спикера","label":"ФИО спикера","invalid":errors.length > 0,"error":errors[0]},model:{value:(speaker.name),callback:function ($$v) {_vm.$set(speaker, "name", $$v)},expression:"speaker.name"}})]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Должность спикера","label":"Должность спикера","invalid":errors.length > 0,"error":errors[0]},model:{value:(speaker.position),callback:function ($$v) {_vm.$set(speaker, "position", $$v)},expression:"speaker.position"}})]}}],null,true)})],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"file-uploader"},[_c('FileUploader',{attrs:{"placeholder":"Фото спикера","label":"Фото спикера","initialFileName":_vm.speakerFileName[index],"rules":"required|image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(speaker.imageId),callback:function ($$v) {_vm.$set(speaker, "imageId", $$v)},expression:"speaker.imageId"}})],1),_c('VBtn',_vm._b({staticClass:"ml-6",attrs:{"color":"red","outlined":"","prepend":""},on:{"click":function($event){return _vm.removeSpeaker(index)}}},'VBtn',_vm.$attrs,false),[_c('VIcon',{staticClass:"mr-3"},[_vm._v("mdi-minus")]),_vm._v("Удалить спикера")],1)],1)])}),_c('div',{staticClass:"row mt-3"},[_c('ButtonWithPlus',{on:{"click":_vm.addSpeaker}},[_vm._v(_vm._s(_vm.textBtn))])],1)],2)],1),_c('div',{staticClass:"btn__container"},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VSwitch',{attrs:{"flat":"","inset":"","label":"Закрепить на главной"},model:{value:(_vm.form.fixed),callback:function ($$v) {_vm.$set(_vm.form, "fixed", $$v)},expression:"form.fixed"}})]}}],null,false,3115106044)})],1)]),_c('ControlButtons',{staticClass:"mt-3",attrs:{"submitting":_vm.isSubmitting,"isAddMode":_vm.isAddMode},on:{"addControlCallback":_vm.addEvent,"updControlCallback":_vm.changeEvent,"delControlCallback":function($event){return _vm.confirmDelete('события', 'событие', _vm.deleteEvent)}}}),_c('Confirmation',{ref:"confirm"})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }