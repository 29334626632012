import '@/utils/services/hooks'

import Vue from 'vue'
import VueMask from 'v-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import router from '@/router'
import store from '@/store'
import vuetify from '@/utils/plugins/vuetify'
import '@/utils/plugins/meta'
import '@/utils/plugins/vee-validate'

import 'tiptap-vuetify/dist/main.css'

import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueMask)

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
})

/**
 * Шина событий сквозь все приложение
 * Использование: this.$bus.$on('SOME_MESSAGE', this.someMethod)
 */
Object.defineProperty(Vue.prototype,'$bus', {
  get: function () {
    return this.$root.bus
  },
})

new Vue({
  data: {
    bus: new Vue({}),
  },
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount('#app')
