import { Component } from 'vue-property-decorator'

// store
import commonStore from '@/store/modules/common'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'

@Component
export default class PeopleMixin extends NotifyMixin {
  protected get people() {
    return commonStore.people
  }

  protected get mapOfPeople() {
    const mapOfPeople: Map<number, string> = new Map()
    this.people.forEach((person) => person.id && mapOfPeople.set(person.id, person.name))
    return mapOfPeople
  }

  protected created() {
    if (!commonStore.people.length) {
      commonStore.getPeople({ perPage: -1 })
        .catch((err) => this.notifyError(err))
    }
  }
}