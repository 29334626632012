
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class InputChips extends Vue {

  private innerValue: string[] = []
  private search = ''

  @Prop({ default: [] })
  private value!: string[]

  private mounted() {
    this.innerValue = this.value
  }

  private updateTags() {
    this.$nextTick(() => {
      this.innerValue.push(...this.search.split(','))
      this.$nextTick(() => {
        this.search = ''
      })
    })
  }

  @Watch('innerValue')
  private watchInnerValue (value: string[]) {
    this.$emit('updateTags', value)
  }
}
