


















import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// interfaces
import { IQuoteRequest } from '@/store/types/home'
// components
import QuoteCard from '@/components/views/home/QuoteCard.vue'
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'

@Component({
  components: {
    ButtonWithPlus,
    QuoteCard,
    ValidationObserver,
  },
})
export default class HomeQuotes extends NotifyMixin {

  @Prop({ required: true })
  private form!: IQuoteRequest[]

  @Prop({ default: null })
  private filesName!: string[] | null

  private formInner: IQuoteRequest[] = []

  private emptyQuote: IQuoteRequest = {
    author: '',
    position: '',
    content: '',
    photoId: null,
    published: false,
  }

  private mounted() {
    this.formInner = this.form
  }

  private addQuote() {
    this.formInner.push(Object.assign({}, this.emptyQuote))
  }

  private deleteQuote(idx: number) {
    this.formInner.splice(idx, 1)
  }

  private updateQuote(idx: number, value: IQuoteRequest) {
    this.formInner.splice(idx, 1, value)
  }

  private startLoad() {
    this.$emit('startLoadFile')
  }

  private endLoad() {
    this.$emit('endLoadFile')
  }

  @Watch('formInner', { deep: true })
  watchForm(value: IQuoteRequest[]) {
    this.$emit('update:form', { quotes: [ ...value ] })
  }
}
