import { Component } from 'vue-property-decorator'

// store
import projectsModule from '@/store/modules/projects'

// mixins
import NotifyMixin from './NotifyMixin'

@Component
export default class ProjectMixin extends NotifyMixin {
  protected get projects() {
    return projectsModule.projects
  }

  protected created() {
    if (!projectsModule.projects.length) {
      projectsModule.fetchProjects()
        .catch((err) => this.notifyError(err))
    }
  }
}