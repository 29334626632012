import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'

import { INewsList, INewsListResponse, INewsRequest, INewsResponse } from '../types'

@Module({
  dynamic: true,
  name: 'news',
  store,
})
class News extends VuexModule {
  // ------------------------------ NEWS ------------------------------ >>
  currentNewsId = -1

  newsList: INewsListResponse[] = []

  news: INewsResponse | null = null

  @Mutation
  setCurrentNewsId(payload: number) {
    this.currentNewsId = payload
  }

  @Mutation
  setNewsList(payload: INewsListResponse[]) {
    this.newsList = payload
  }

  @Mutation
  setNews(payload: INewsResponse) {
    this.news = payload
  }

  @Action({ rawError: true })
  async fetchNewsList(params: any): Promise<INewsList> {
    const { data } = await api.get(
      '/news',
      { params },
    )

    this.setNewsList(data)

    return data
  }

  @Action({ rawError: true })
  async fetchNews(): Promise<INewsResponse> {
    const { data } = await api.get(
      `/news/${this.currentNewsId}`,
    )
    
    this.setNews(data)

    return data
  }

  @Action({ rawError: true })
  async addNews(payload: INewsRequest): Promise<INewsResponse> {
    const { data } = await api.post(
      '/news',
      payload,
    )

    this.setNews(data)

    return data
  }

  @Action({ rawError: true })
  async updateNews(payload: INewsRequest): Promise<INewsResponse> {
    const { data } = await api.put(
      `/news/${this.currentNewsId}`,
      payload,
    )

    this.setNews(data)

    return data
  }

  @Action({ rawError: true })
  async deleteNews(): Promise<void> {
    const { data } = await api.delete(
      `/news/${this.currentNewsId}`,
    )

    return data
  }
}

const newsModule = getModule(News)

export default newsModule
