import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'

@Component
export default class ConfirmationMixin extends Vue {
  @Ref() protected confirm!: Confirmation

  protected confirmDelete (
    wordGenetive: string, 
    wordAccusative: string, 
    callback: (...args: any[]) => void,
    callbackArg?: number|string,
  ): void {
    this.confirm.open(
      `Удаление ${wordGenetive}`,
      `Вы уверены, что хотите удалить ${wordAccusative}?`,
      {
        buttonConfirmText: 'Удалить',
      },
    ).then(() => callback(callbackArg))
  }

  protected confirmLogout (callback: (...args: any[]) => void): void {
    this.confirm.open(
      'Выход из системы',
      'Вы уверены, что хотите выйти из системы?',
      {
        buttonConfirmText: 'Выйти',
      },
    ).then(() => callback())
  }
}
