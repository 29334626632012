import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import middlewarePipeline from '@/router/_middleware/pipeline'
// Routes
import authRoute from '@/router/auth'
import homeRoute from '@/router/home'
import projectsRoute from '@/router/projects'
import regionRoute from '@/router/regions'
import newsRouter from '@/router/news'
import eventsRouter from '@/router/events'
import mediaRouter from '@/router/media'
import councilRouter from '@/router/publicCouncil'
import managersRouter from '@/router/managers'
import oauthRouter from '@/router/oauth'
import interviewRouter from '@/router/interview'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: 'main',
  },
  authRoute,
  ...homeRoute,
  ...projectsRoute,
  ...regionRoute,
  ...newsRouter,
  ...eventsRouter,
  ...mediaRouter,
  ...councilRouter,
  ...managersRouter,
  ...oauthRouter,
  ...interviewRouter,
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next, to }

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  })
})
export default router
