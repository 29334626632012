































































































































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import { TiptapVuetify, Bold, Paragraph, Link } from 'tiptap-vuetify'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import RegionMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
import UserMixin from '@/mixins/UserMixin'
// components
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import ControlButtons from '@/components/ControlButtons.vue'
import InputChips from '@/components/_uikit/controls/InputChips.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// interfaces
import { INewsRequest, INewsResponse } from '@/store/types'
// store
import commonStore from '@/store/modules/common'
import newsModule from '@/store/modules/news'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'

@Component({
  components: {
    Select,
    TextInput,
    DatePicker,
    FileUploader,
    TextAreaInput,
    ControlButtons,
    ValidationObserver,
    ValidationProvider,
    InputChips,
    Confirmation,
    TiptapVuetify,
  },
})
export default class NewsItem extends mixins(NotifyMixin, ProjectMixin, RegionMixin, ConfirmationMixin, UserMixin) {

  private extensions = [
    Bold,
    Paragraph,
    Link,
  ]

  private isValid = false

  private isLoading = true

  private isSubmitting = false

  private id = +this.$route.params.newsID

  private previewFileName: string | null = null
  private galleryFilesName: string[] | null = null

  // private types = [
  //   { name: 'Новость', value: 'news' },
  //   { name: 'Опрос', value: 'interview' },
  // ]

  private get isAddMode() {
    return this.$route.name === 'news.add'
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.isAddMode ? 'Добавление новости' : 'Редактирование новости',
    }
  }

  private form: INewsRequest = {
    caption: '',
    type: 'news',
    publishedAt: '',
    regionId: null,
    projectId: null,
    previewId: null,
    mediaDescription: '',
    content: '',
    gallery: [],
    tags: [],
  }

  private content = ''

  private newNews() {
    commonStore.setBreadcrumbs([
      { name: 'Новости', path: 'main' },
      { name: 'Добавление новости', path: this.$route.path },
    ])

    this.isLoading = false

    this.previewFileName = ''
  }

  private updateNews() {
    commonStore.setBreadcrumbs([
      { name: 'Новости', path: 'main' },
      { name: 'Редактирование новости', path: this.$route.path },
    ])

    if (!newsModule.news || this.id !== newsModule.currentNewsId) {
      newsModule.setCurrentNewsId(this.id)
      newsModule.fetchNews()
        .then((response: INewsResponse) => {
          this.form = this.transformData(response)
          this.previewFileName = response.preview ? response.preview.originalName : ''
          this.galleryFilesName = response.gallery.map(file => file.originalName)
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoading = false)
    } else {
      this.form = this.transformData(newsModule.news)
      this.previewFileName = newsModule.news.preview ? newsModule.news.preview.originalName : ''
      this.galleryFilesName = newsModule.news.gallery.map(file => file.originalName)
      this.isLoading = false
    }
  }

  private transformData(data: INewsResponse): INewsRequest {
    return {
      caption: data.caption,
      type: data.type,
      publishedAt: data.publishedAt,
      regionId: data.regionId,
      projectId: data.projectId,
      previewId: data.preview ? data.preview.id : null,
      mediaDescription: data.mediaDescription,
      content: data.content,
      gallery: data.gallery.map(item => item.id),
      tags: data.tags,
    }
  }

  private mounted() {
    if (this.isAddMode && !this.isAdmin) {
      this.form.regionId = this.userRegionId
    }

    this.isAddMode ? this.newNews() : this.updateNews()
    this.content = this.form && this.form.content ? this.form.content : ''
  }

  private updateTags(value: string[]) {
    if (this.form) {
      this.form.tags = value
    }
  }

  private removeNews() {
    this.isSubmitting = true
    newsModule.deleteNews()
      .then(() => {
        this.$router.push({ name: 'news' })
        this.notifySuccess('Новость успешно удалена!')
      })
      .catch(err => this.notifyError(err))
      .finally(() => this.isSubmitting = false)
  }

  private changeNews() {

    const form: any = this.$refs.form

    if (this.form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.isSubmitting = true
            newsModule.updateNews(this.form)
              .then(() => {
                this.$router.push({ name: 'news.list' })
                this.notifySuccess('Новость успешно изменена!')
              })
              .catch(err => this.notifyError(err))
              .finally(() => this.isSubmitting = false)
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }

  }

  private addNews() {

    const form: any = this.$refs.form

    if (this.form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.isSubmitting = true
            newsModule.addNews(this.form)
              .then(() => {
                this.notifySuccess('Новость успешно создана!')
                this.$router.push({ name: 'news' })
              })
              .catch(err => this.notifyError(err))
              .finally(() => this.isSubmitting = false)
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }

  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }

  @Watch('form.content')
  private formContentWatch(value) {
    if (value === '' || value === '<p></p>') {
      this.content = ''
    } else {
      this.content = value
    }
  }
}
