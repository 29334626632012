var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.form)?_c('ValidationObserver',{ref:"form",staticClass:"d-flex flex-column",attrs:{"tag":"div"}},[_c('VForm',{staticClass:"d-flex flex-column justify-space-between",attrs:{"isValid":_vm.isValid}}),_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Название","label":"Название","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}})]}}],null,false,528848005)})],1),_c('div',{staticClass:"custom__layout"},[_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"placeholder":"Дата","label":"Дата","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.publishedAt),callback:function ($$v) {_vm.$set(_vm.form, "publishedAt", $$v)},expression:"form.publishedAt"}})]}}],null,false,4252000063)}),_c('ValidationProvider',{staticClass:"mx-6",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.regions,"disabled":!_vm.isAdmin,"placeholder":"Регион","label":"Регион","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.regionId),callback:function ($$v) {_vm.$set(_vm.form, "regionId", $$v)},expression:"form.regionId"}})]}}],null,false,3698874771)}),_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.projects,"placeholder":"Проект","label":"Проект","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.projectId),callback:function ($$v) {_vm.$set(_vm.form, "projectId", $$v)},expression:"form.projectId"}})]}}],null,false,3151820929)})],1),_c('div',{staticClass:"d-flex align-center mb-3"},[_c('div',{staticClass:"file-uploader mr-8"},[_c('FileUploader',{attrs:{"initialFileName":_vm.previewFileName,"label":"Превью","placeholder":"Превью","rules":"image|size:5120","message":"Размер файлов не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.previewId),callback:function ($$v) {_vm.$set(_vm.form, "previewId", $$v)},expression:"form.previewId"}})],1),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Описание медиаматериала","label":"Описание медиаматериала","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.mediaDescription),callback:function ($$v) {_vm.$set(_vm.form, "mediaDescription", $$v)},expression:"form.mediaDescription"}})]}}],null,false,1830461655)})],1),_c('div',{staticClass:"tiptap-container mb-6"},[_c('TiptapVuetify',{staticClass:"tiptap",attrs:{"extensions":_vm.extensions,"placeholder":"Содержание","label":"Содержание","no-resize":"","rule":[]},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('ValidationProvider',{staticClass:"tiptap__input",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"","label":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})]}}],null,false,2950292176)})],1),_c('FileUploader',{staticClass:"mb-3",attrs:{"initialFileName":_vm.galleryFilesName,"label":"Галерея","placeholder":"Галерея","multiple":true,"rules":"image|size:5120","message":"Размер файлов не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.gallery),callback:function ($$v) {_vm.$set(_vm.form, "gallery", $$v)},expression:"form.gallery"}}),_c('ControlButtons',{attrs:{"isAddMode":_vm.isAddMode,"submitting":_vm.isSubmitting},on:{"addControlCallback":_vm.addNews,"updControlCallback":_vm.changeNews,"delControlCallback":function($event){return _vm.confirmDelete('новости', 'новость', _vm.removeNews)}}}),_c('Confirmation',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }