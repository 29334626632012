import { RouteConfig } from 'vue-router' 

// page
import Regions from '@/views/regions/index.vue'
// subpages
import Region from '@/views/regions/region/index.vue'
import RegionsContent from '@/views/regions/content/index.vue'

import auth from './_middleware/auth'

const route: RouteConfig[] = [
  {
    children: [
      {
        component: Region,
        name: 'region.region',
        path: ':regionID(\\d+)',
        meta: { middleware: [auth] },
      },
      {
        component: RegionsContent,
        name: 'region.content',
        path: 'main',
        meta: { middleware: [auth] },
      },
    ],
    component: Regions,
    name: 'region',
    path: '/regions',
    redirect: '/regions/main',
  },
]

export default route