import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'

import { IPerson, IPersonBrief, IPersonList } from '../types'

@Module({
  dynamic: true,
  name: 'publicCouncil',
  store,
})
class PublicCouncil extends VuexModule {
  // ------------------------------ PUBLIC-COUNCIL ------------------------------ >>
  currentCouncilPerson = -1

  person: IPerson | null = null

  persons: IPerson[] = []

  @Mutation
  setCurrentCouncilPerson(payload: number) {
    this.currentCouncilPerson = payload
  }

  @Mutation
  setPerson(payload: IPerson) {
    this.person = payload
  }

  @Mutation
  setPersons(payload: IPerson[]) {
    this.persons = payload
  }

  @Action({ rawError: true })
  async fetchPersons(params: any): Promise<IPersonList> {
    const { data } = await api.get(
      '/people',
      { params },
    )

    this.setPersons(data.data)

    return data
  }

  @Action({ rawError: true })
  async fetchPublicCouncilPerson(id: number): Promise<IPerson> {
    const { data } = await api.get(
      `/people/${id}`,
    )

    this.setPerson(data)  

    return data
  }

  @Action({ rawError: true })
  async updateCouncilMember(payload: IPersonBrief): Promise<IPerson> {
    const { data } = await api.put(
      `/people/${this.currentCouncilPerson}`,
      payload,
    )

    this.setPerson(data)

    return data
  } 

  @Action({ rawError: true })
  async addNewPerson(payload: IPersonBrief): Promise<IPerson> {
    const { data } = await api.post(
      '/people',
      payload,
    )

    return data
  }

  @Action({ rawError: true })
  async deletePerson(): Promise<void> {
    const { data } = await api.delete(
      `/people/${this.currentCouncilPerson}`,
    )

    return data
  }
}

const councilModule = getModule(PublicCouncil)

export default councilModule
