





import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class NotFoundMessage extends Vue {
  
  @Prop({ default: 'Ничего не найдено.' })
  private message!: string

}
