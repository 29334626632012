









































































































































































































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import moment from 'moment'
import { TiptapVuetify, Bold, Paragraph, Link } from 'tiptap-vuetify'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import RegionMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
import UserMixin from '@/mixins/UserMixin'
// components
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import ControlButtons from '@/components/ControlButtons.vue'
import InputChips from '@/components/_uikit/controls/InputChips.vue'
import TimePicker from '@/components/_uikit/controls/TimePicker.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// store
import commonStore from '@/store/modules/common'
import eventsModule from '@/store/modules/events'
// interfaces
import { IEventResponse, IEventRequest } from '@/store/types'
// utils
import { formatTime, formatDate } from '@/utils/functions'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'

@Component({
  components: {
    InputChips,
    TimePicker,
    TextInput,
    DatePicker,
    Select,
    FileUploader,
    TextAreaInput,
    ButtonWithPlus,
    ControlButtons,
    ValidationObserver,
    ValidationProvider,
    Confirmation,
    TiptapVuetify,
  },
})
export default class EventsItem extends mixins(NotifyMixin, RegionMixin, ProjectMixin, ConfirmationMixin, UserMixin) {

  private extensions = [
    Bold,
    Paragraph,
    Link,
  ]

  private isValid = false

  private isLoading = true

  private isSubmitting = false

  private form: IEventRequest | null = null

  private id = +this.$route.params.eventId

  private coverFileName: string | null = null
  private schemeFileName: string | null = null
  private speakerFileName: string[] | null = null

  private timeStart: string | null = null
  private timeEnd: string | null = null
  private dateRange: string[] | null = null

  private get textBtn () {
    return this.form && this.form.speakers.length ? 'Добавить еще спикера' : 'Добавить спикера'
  }

  private get isAddMode() {
    return this.$route.name === 'events.add'
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.isAddMode ? 'Добавление события' : 'Редактирование события',
    }
  }

  private newEvent() {
    commonStore.setBreadcrumbs([
      { name: 'События', path: 'main' },
      { name: 'Добавление события', path: this.$route.path },
    ])

    this.isLoading = false

    this.coverFileName = ''
    this.schemeFileName = ''
    this.speakerFileName = []

    this.timeStart = ''
    this.timeEnd = ''
    this.dateRange = []

    this.form = {
      caption: '',
      publishedAt: '',
      published: false,
      regionId: this.isAdmin ? null : this.userRegionId,
      projectId: null,
      coverId: null,
      startDate: '',
      endDate: '',
      place: '',
      content: '',
      schemeId: null,
      videoLink: '',
      fixed: false,
      tags: [],
      speakers: [],
    }
  }

  private content = ''

  private updateEvent() {
    commonStore.setBreadcrumbs([
      { name: 'События', path: 'main' },
      { name: 'Редактирование события', path: this.$route.path },
    ])

    if (!eventsModule.event || this.id !== eventsModule.currentEventId) {
      eventsModule.setCurrentEventId(this.id)
      eventsModule.fetchEventItem()
        .then((response: IEventResponse) => {
          this.form = this.transformData(response)
          this.coverFileName = response.cover ? response.cover.originalName : ''
          this.schemeFileName = response.scheme ? response.scheme.originalName : ''
          this.speakerFileName = response.speakers.map(speaker => speaker.image ? speaker.image.originalName : '')
          this.timeStart = response.showTime ? formatTime(response.startDate) : null
          this.timeEnd = response.showTime ? formatTime(response.endDate) : null
          this.dateRange = [formatDate(response.startDate, 'yyyy-MM-dd'), formatDate(response.endDate, 'yyyy-MM-dd')]
        })
        .catch(err => {
          this.notifyError(err)
        })
        .finally(() => this.isLoading = false)
    } else {
      this.form = this.transformData(eventsModule.event)
      this.coverFileName = eventsModule.event.cover ? eventsModule.event.cover.originalName : ''
      this.schemeFileName = eventsModule.event.scheme ? eventsModule.event.scheme.originalName : ''
      this.speakerFileName = eventsModule.event.speakers.map(speaker => speaker.image ? speaker.image.originalName : '')
      this.timeStart = eventsModule.event.showTime ? formatTime(eventsModule.event.startDate) : null
      this.timeEnd = eventsModule.event.showTime ? formatTime(eventsModule.event.endDate) : null
      this.dateRange = [formatDate(eventsModule.event.startDate, 'yyyy-MM-dd'), formatDate(eventsModule.event.endDate, 'yyyy-MM-dd')]
      this.isLoading = false
    }
  }

  private mounted() {
    this.isAddMode ? this.newEvent() : this.updateEvent()
    this.content = this.form && this.form.content ? this.form.content : ''
  }

  private transformData(data: IEventResponse): IEventRequest {
    return {
      caption: data.caption,
      publishedAt: data.publishedAt,
      published: data.published,
      regionId: data.regionId,
      projectId: data.projectId,
      coverId: data.cover ? data.cover.id : null,
      startDate: data.startDate,
      endDate: data.endDate,
      place: data.place,
      content: data.content,
      schemeId: data.scheme ? data.scheme.id : null,
      videoLink: data.videoLink ?? '',
      fixed: data.fixed,
      tags: data.tags,
      speakers: data.speakers ? data.speakers.map(speaker => ({
        name: speaker.name,
        position: speaker.position,
        imageId: speaker.image ? speaker.image.id : null,
      })) : [],
    }
  }

  private addSpeaker() {
    if (this.form) {
      this.form.speakers.push({
        name: '',
        position: '',
        imageId: null,
      })
    }
  }

  private removeSpeaker(index: number) {
    if (this.form) {
      this.form.speakers = this.form.speakers.filter((speaker, idx) => idx !== index)
    }
  }

  private updateTags(value: string[]) {
    if (this.form) {
      this.form.tags = value
    }
  }

  private deleteEvent() {
    this.isSubmitting = true
    eventsModule.deleteEvent()
      .then(() => {
        this.$router.push({ name: 'events' })
        this.notifySuccess('Событие успешно удалено!')
      })
      .catch(err => this.notifyError(err))
      .finally(() => this.isSubmitting = false)
  }

  private changeEvent() {

    if (this.form) {

      let startDate = ''
      let endDate = ''

      if (this.dateRange && this.dateRange.length && this.timeStart) {
        startDate = `${this.dateRange[0]} ${this.timeStart}`
      } else if (this.dateRange && this.dateRange.length && !this.timeStart) {
        startDate = `${this.dateRange[0]}`
      }

      if (this.dateRange && this.dateRange.length && this.timeEnd) {
        endDate = `${this.dateRange[1]} ${this.timeEnd}`
      } else if (this.dateRange && this.dateRange.length && !this.timeEnd) {
        endDate = `${this.dateRange[1]}`
      }

      const data: IEventRequest = {
        ...this.form,
        startDate,
        endDate,
      }
      const form: any = this.$refs.form

      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            if (this.checkDateTimeRange) {
              if (this.form) {
                this.isSubmitting = true
                eventsModule.updateEvent(data)
                  .then(() => {
                    this.$router.push({ name: 'events.list' })
                    this.notifySuccess('Событие успешно изменено!')
                  })
                  .catch(err => this.notifyError(err))
                  .finally(() => this.isSubmitting = false)
              }
            } else {
              this.notifyError('Некорректный диапазон дат и времени')
            }
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }
  }

  private addEvent() {

    if (this.form) {

      let startDate = ''
      let endDate = ''

      if (this.dateRange && this.dateRange.length && this.timeStart) {
        startDate = `${this.dateRange[0]} ${this.timeStart}`
      } else if (this.dateRange && this.dateRange.length && !this.timeStart) {
        startDate = `${this.dateRange[0]}`
      }

      if (this.dateRange && this.dateRange.length && this.timeEnd) {
        endDate = `${this.dateRange[1]} ${this.timeEnd}`
      } else if (this.dateRange && this.dateRange.length && !this.timeEnd) {
        endDate = `${this.dateRange[1]}`
      }

      const data: IEventRequest = {
        ...this.form,
        startDate,
        endDate,
      }
      const form: any = this.$refs.form

      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            if (this.checkDateTimeRange) {
              if (this.form) {
                this.isSubmitting = true
                eventsModule.addEvent(data)
                  .then(() => {
                    this.notifySuccess('Событие успешно добавлено!')
                    this.$router.push({ name: 'events' })
                  })
                  .catch(err => this.notifyError(err))
                  .finally(() => this.isSubmitting = false)
              }
            } else {
              this.notifyError('Некорректный диапазон дат и времени')
            }
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }
  }

  private get checkDateTimeRange() {
    if (this.dateRange && this.dateRange.length && this.timeStart && this.timeEnd) {
      return moment(`${this.dateRange[0]} ${this.timeStart}`).isBefore(`${this.dateRange[1]} ${this.timeEnd}`) || moment(`${this.dateRange[0]} ${this.timeStart}`).isSame(`${this.dateRange[1]} ${this.timeEnd}`)
    } else if (this.dateRange && this.dateRange.length) {
      return moment(`${this.dateRange[0]}`).isBefore(`${this.dateRange[1]}`) || moment(`${this.dateRange[0]}`).isSame(`${this.dateRange[1]}`)
    }
    return false
  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }

  @Watch('form.content')
  private formContentWatch(value) {
    if (value === '' || value === '<p></p>') {
      this.content = ''
    } else {
      this.content = value
    }
  }

}
