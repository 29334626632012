














import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// interfaces
import { ISwitcher } from '@/store/types'
import { IShowBlockOptions } from '@/store/types/home'
// store
import homeModule from '@/store/modules/home'

@Component({
  components: {
    
  },
})
export default class HomeShowBlock extends NotifyMixin {

  @Prop({ required: true })
  private form!: IShowBlockOptions

  private get showBlock() {
    return homeModule.homePageData ? homeModule.homePageData.displayBlocks : []
  }

  private switchers: ISwitcher[] = [
    {
      dataName: 'projectMap',
      name: 'Карта проектов',
      value: false,
    },
    {
      dataName: 'listOfProjects',
      name: 'Список проектов',
      value: false,
    },
    {
      dataName: 'news',
      name: 'Новости',
      value: false,
    },
    {
      dataName: 'events',
      name: 'События',
      value: false,
    },
    {
      dataName: 'bannerNationalProjects',
      name: 'Баннер Нацпроектов',
      value: false,
    },
    {
      dataName: 'quotes',
      name: 'Цитаты',
      value: false,
    },
    {
      dataName: 'mediaMaterials',
      name: 'Медиаматериалы',
      value: false,
    },
  ]

  private formInner: IShowBlockOptions = {
    bannerNationalProjects: false,
    events: false,
    listOfProjects: false,
    projectMap: false,
    mediaMaterials: false,
    news: false,
    quotes: false,
  }

  private mounted() {
    this.formInner = this.form
  }

  @Watch('formInner', { deep: true })
  watchForm(value: IShowBlockOptions) {
    this.$emit('update:form', { displayBlocks: { ...value } })
  }

}
