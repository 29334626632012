



































import Component from 'vue-class-component'

// store
import { ILink } from '@/store/types'
// mixins
import AuthMixin from '@/mixins/UserMixin'

@Component
export default class NavigationMenu extends AuthMixin {

  private get links(): ILink[] {
    return [
      {
        name: 'Главная',
        path: '/main',
        visible: ['federal'],
      },
      {
        name: 'Проекты',
        path: '/projects/main',
        visible: ['federal'],
      },
      {
        name: 'Проекты',
        path: '/projects/regions',
        visible: ['regional'],
      },
      {
        name: 'Регионы',
        path: '/regions/main',
        visible: ['federal', 'regional'],
      },
      {
        name: 'Новости',
        path: '/news/main',
        visible: ['federal', 'regional'],
      },
      {
        name: 'Медиаматериалы',
        path: '/media/main',
        visible: ['federal', 'regional'],
      },
      {
        name: 'События',
        path: '/events/main',
        visible: ['federal', 'regional'],
      },
      {
        name: 'Сотрудники',
        path: '/public-council/main',
        visible: ['federal'],
      },
      {
        name: 'Региональные менеджеры',
        path: '/managers/main',
        visible: ['federal'],
      },
      {
        name: 'Опросы',
        path: '/interview/main',
        visible: ['federal', 'regional'],
      },
    ]
  }

}
