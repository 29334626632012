import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'

import { IEventPublished, IEventRequest, IEventResponse, IEventsList, IEventsResponse } from '../types'

@Module({
  dynamic: true,
  name: 'events',
  store,
})
class Events extends VuexModule {
  // ------------------------------ EVENTS ------------------------------ >>
  currentEventId = -1

  events: IEventsResponse[] = []
  event: IEventResponse | null = null

  @Mutation
  setCurrentEventId(payload: number) {
    this.currentEventId = payload
  }

  @Mutation
  setEvents(payload: IEventsResponse[]) {
    this.events = payload
  }

  @Mutation
  setEvent(payload: IEventResponse) {
    this.event = payload
  }

  @Mutation
  setEventPublished(payload: IEventPublished) {
    const event = this.events.find(event => event.id === payload.id)
    if (event) {
      event.published = payload.published
    }
  }

  @Action({ rawError: true })
  async fetchEvents(params: any): Promise<IEventsList> {
    const { data } = await api.get(
      '/events',
      { params },
    )

    this.setEvents(data.data)

    return data
  }

  @Action({ rawError: true })
  async addEvent(payload: IEventRequest): Promise<IEventResponse> {
    const { data } = await api.post(
      '/events',
      payload,
    )

    this.setEvent(data)

    return data
  }

  @Action({ rawError: true })
  async publishedEvent(id: number): Promise<IEventPublished> {
    const { data } = await api.post(
      `/events/${id}/published`,
    )

    if (this.event && this.event.id === id) {
      this.setEvent({
        ...this.event,
        published: data.published,
      })
    }

    this.setEventPublished(data)

    return data
  }

  @Action({ rawError: true })
  async fetchEventItem(): Promise<IEventResponse> {
    const { data } = await api.get(
      `/events/${this.currentEventId}`,
    )

    this.setEvent(data)

    return data
  }

  @Action({ rawError: true })
  async updateEvent(payload: IEventRequest): Promise<IEventResponse> {
    const { data } = await api.put(
      `/events/${this.currentEventId}`,
      payload,
    )

    this.setEvent(data)

    return data
  }

  @Action({ rawError: true })
  async deleteEvent(): Promise<void> {
    const { data } = await api.delete(
      `/events/${this.currentEventId}`,
    )

    return data
  }
}

const eventsModule = getModule(Events)

export default eventsModule
