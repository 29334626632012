import { RouteConfig } from 'vue-router'

// page
import Media from '@/views/media/index.vue'
// subpages
import MediaItem from '@/views/media/item/index.vue'
import MediaList from '@/views/media/list/index.vue'

import auth from './_middleware/auth'

const route: RouteConfig[] = [
  {
    children: [
      {
        name: 'media.item',
        component: MediaItem,
        path: ':mediaId(\\d+)',
        meta: { middleware: [auth] },
      },
      {
        name: 'media.add',
        component: MediaItem,
        path: 'add',
        meta: { middleware: [auth] },
      },
      {
        name: 'media.list',
        component: MediaList,
        path: 'main',
        meta: { middleware: [auth] },
      },
    ],
    component: Media,
    name: 'media',
    path: '/media',
    redirect: '/media/main',
  },
]

export default route
