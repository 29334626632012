







































// Basic
import { Component } from 'vue-property-decorator'

// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// Components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// Interface
import { ILoginForm } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    TextInput,
  },
})
export default class AuthForm extends NotifyMixin {

  private isValid = false

  private form: ILoginForm = {
    email: '',
    password: '',
  }

  private get logoPath() {
    return require('@/assets/images/logo.svg')
  }

  private handleLogin () {
    AuthModule.login(this.form)
      .then(() => {
        localStorage.setItem('auth-token', AuthModule.token)
        localStorage.setItem('auth-user', JSON.stringify(AuthModule.user))
        this.$router.push('/')
      })
      .catch(() => {
        this.notifyError('Данные введены не корректно')
      })
  }

  private handleERLogin () {
    AuthModule.getUrlForERLogin()
      .then(url => {
        window.location.href = url.toString()
      })
  }

  private get emailRules() {
    return [
      (v: string) => !!v || 'Поле обязательно для заполнения',
      // (v: string) => /.+@.+\..+/.test(v) || 'Некорректный e-mail адрес',
    ]
  }

  private get passwordRules() {
    return [
      (v: string) => !!v || 'Поле обязательно для заполнения',
    ]
  }

}
