





























































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { isEmpty } from 'lodash'
import { MetaInfo } from 'vue-meta'

// mixins
import RegionMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
import UserMixin from '@/mixins/UserMixin'
// components
import MediaListContent from '@/components/views/media/MediaListContent.vue'
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
import Pagination from '@/components/_uikit/Pagination.vue'
import Spinner from '@/components/_uikit/Spinner.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import commonStore from '@/store/modules/common'
import mediaModule from '@/store/modules/media'
// interfaces
import { IMediaList, IMediaListResponse } from '@/store/types'
// utils
import { isBeforeDate } from '@/utils/functions'

@Component({
  components: {
    Select,
    DatePicker,
    MediaListContent,
    NotFoundMessage,
    Pagination,
    Spinner,
    TextInput,
  },
})
export default class MediaList extends mixins(RegionMixin, ProjectMixin, UserMixin) {

  private media: IMediaListResponse[] = []

  private loading = false

  private page = 1

  private regionIdParam = this.$route.query.regionId ? +this.$route.query.regionId : null

  private projectIdParam = this.$route.query.projectId ? +this.$route.query.projectId : null

  private searchParam = this.$route.query.searchParam ? this.$route.query.searchParam.toString() : ''

  private dateStart = this.$route.query.dateStart ? this.$route.query.dateStart.toString() : ''
  private dateEnd = this.$route.query.dateEnd ? this.$route.query.dateEnd.toString() : ''
  private date = [this.dateStart, this.dateEnd]

  private mediaPerPage = 15

  private pageAmount = Math.floor(this.media?.length / this.mediaPerPage)

  private addNewMedia() {
    this.$router.push({ name: 'media.add' })
  }

  private getMedia(resetPage = false) {
    this.page = typeof resetPage === 'boolean' && resetPage ? this.page : 1

    if (!this.isAdmin) {
      this.regionIdParam = this.userRegionId
    }

    const { dateStart, dateEnd } = isBeforeDate(this.date[0], this.date[1])
    this.dateStart = dateStart
    this.dateEnd = dateEnd

    this.loading = true

    mediaModule.fetchMedia({
      page: this.page,
      projectId: this.projectIdParam,
      regionId: this.regionIdParam,
      date1: this.dateStart,
      date2: this.dateEnd,
      search: this.searchParam,
    })
      .then((response: IMediaList) => {
        this.media = response.data
        this.pageAmount = response.meta.lastPage
      })
      .finally(() => {
        this.loading = false
      })
  }

  private changePage() {
    this.getMedia(true)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Медиаматериалы',
    }
  }

  private mounted() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Медиаматериалы', path: this.$route.path },
      ])
    }, 10)
    this.getMedia()
  }

  @Watch('regionIdParam')
  private watchRegionIdParam() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, regionId: this.regionIdParam ? this.regionIdParam.toString() : '' } })
  }

  @Watch('projectIdParam')
  private watchProjectIdParam() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, projectId: this.projectIdParam ? this.projectIdParam.toString() : '' } })
  }

  @Watch('date')
  private watchDate() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, dateStart: this.dateStart, dateEnd: this.dateEnd } })
  }

  @Watch('searchParam')
  private watchSearchParam(value: string) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, searchParam: this.searchParam } })

    if (value && value?.length > 2) {
      this.getMedia()
    } else if (!value) {
      this.getMedia()
    }
  }

  @Watch('$route.query')
  private watchParams() {
    if (isEmpty(this.$route.query)) {
      this.regionIdParam = null
      this.projectIdParam = null
      this.date = ['', '']

      this.getMedia()
    }
  }

}
