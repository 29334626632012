import Vue from 'vue'
import { Component } from 'vue-property-decorator'

// store
import authModule from '@/store/modules/auth'

@Component
export default class UserMixin extends Vue {
  protected user = authModule.user

  protected get userType() {
    return this.user ? this.user.type : null
  }

  protected get userRegionId() {
    return this.user && this.user.regionId ? this.user.regionId : -1
  }

  protected get isAdmin() {
    return this.user ? this.userType === 'federal' : false
  }
}
