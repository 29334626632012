
























































































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// mixins
import RegionMixin from '@/mixins/RegionMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import UserMixin from '@/mixins/UserMixin'
// store
import projectsModule from '@/store/modules/projects'
// components
import Select from '@/components/_uikit/controls/Select.vue'
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import Spinner from '@/components/_uikit/Spinner.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
// interfaces
import { IProjectBrief, IProjectPublished, IProjectRegionBrief, IProjectRegionTiny } from '@/store/types/projects'
import commonStore from '@/store/modules/common'

@Component({
  components: {
    ButtonWithPlus,
    Select,
    Spinner,
    TextInput,
    NotFoundMessage,
  },
})
export default class ProjectsItems extends mixins(RegionMixin, NotifyMixin, UserMixin) {

  private projects: IProjectBrief[] = []

  private isRegion = this.$route.name === 'projects.region'

  private isLoading = true

  private isSubmitting = false

  private chosenProject = -1

  private chosenRegion = -1

  private innerProjects: any = []

  private search = ''

  private get projectsFilter() {

    if (!this.isRegion) {
      return this.projects.filter((item: IProjectBrief) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    } else {
      if (this.innerProjects && this.innerProjects.length) {
        for(let i = 0; i < this.innerProjects.length; i++) {
          if (this.innerProjects[i].project === null) {
            this.innerProjects.splice(i, 1)
          }
        }
        return this.innerProjects.filter((str: IProjectRegionBrief) => {
          return str.project.name.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1
        })
      }
      return []
    }
  }

  private get routeName () {
    return this.$route.name
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Проекты',
    }
  }

  private mounted() {

    if (!this.isAdmin) {
      this.chosenRegion = this.userRegionId
      projectsModule.setRegionId(this.chosenRegion)
    } else {
      this.chosenRegion = projectsModule.regionId
    }

    this.getProjects()

    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Проекты', path: this.$route.path },
      ])
    }, 10)

    if (this.isRegion) {
      this.fetchProjectsRegion()
    }
  }

  private getProjects() {
    projectsModule.fetchProjects()
      .then((response: IProjectBrief[]) => {
        this.projects = response
      })
      .finally(() => this.isLoading = false)
  }

  private fetchProjectsRegion() {
    this.isLoading = true
    projectsModule.fetchRegionProjects()
      .then((response) => {
        this.innerProjects = response
      })
      .catch((err) => {
        this.notifyError(err)
      })
      .finally(() => this.isLoading = false)
  }

  private get availableProjects(): IProjectRegionTiny[] {
    return this.projects.map(item => {
      return {
        id: item.id,
        name: item.name,
        disabled: (this.innerProjects as IProjectRegionBrief[]).some((_project) => _project.project.id === item.id),
      }
    })
  }

  private addNewRegionProject() {
    if (this.chosenProject === -1) {
      this.notifyError('Выберите проект')
    } else {
      this.$router.push({ name: 'projects.region.add', params: { regionID: this.chosenRegion.toString(), projectID: this.chosenProject.toString() } })
    }
  }

  private changePublished(item: IProjectBrief) {

    this.isSubmitting = true
    projectsModule.publishedProject(item.id)
      .then((response: IProjectPublished) => {
        const message = response.published ? 'Проект отображается' : 'Проект скрыт'
        this.notifyInfo(message)
      })
      .catch(() => this.notifyError('Что-то пошло не так, повторите попытку позже'))
      .finally(() => this.isSubmitting = false)
  }

  private addNewProject() {
    this.$router.push({ name: 'projects.add' })
  }

  private handleRedirect(project: IProjectBrief) {
    if (this.isRegion) {
      this.$router.push({ name: 'projects.region.edit', params: { projectID: project.id.toString() } })
    } else {
      this.$router.push({ name: 'projects.main.item', params: { projectID: project.id.toString() } })
    }
  }

  private projectRegionPublished(id: number) {
    this.isSubmitting = true
    projectsModule.publishedProjectRegion(id)
      .then((response: IProjectPublished) => {
        const message = response.published ? 'Проект в регионе отображается' : 'Проект в регионе скрыт'
        this.notifyInfo(message)
      })
      .catch(() => this.notifyError('Что-то пошло не так, повторите попытку позже'))
      .finally(() => this.isSubmitting = false)
  }

  @Watch('chosenRegion')
  watchChosenRegion(value: number) {
    if (this.isRegion && this.isAdmin) {
      projectsModule.setRegionId(value)
      this.fetchProjectsRegion()
      this.chosenProject = -1
    }
  }

  @Watch('projects')
  watchProjects() {
    if (this.projects.length) {
      this.isLoading = false
    }
  }

  @Watch('routeName')
  watchRoute(value: any) {

    commonStore.setBreadcrumbs([
      { name: 'Проекты', path: this.$route.path },
    ])

    this.isRegion = value === 'projects.region'
    if (!this.isRegion) {

      if (projectsModule.projects.length) {
        this.innerProjects = this.projects
      }

      this.chosenRegion = projectsModule.regionId
    } else {

      if (!projectsModule.projectsRegion?.length) {
        this.fetchProjectsRegion()
      } else this.innerProjects = projectsModule.projectsRegion

    }

    this.search = ''
  }

  @Watch('search')
  private watchSearch(value: string | null) {
    if (!value && typeof value !== 'string') {
      this.search = ''
    }
  }
}
