
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class ControlButtons extends Vue {
  @Prop({ default: false })
  private isAddMode!: boolean

  @Prop({ default: false })
  private submitting!: boolean
}
