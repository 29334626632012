import { RouteConfig } from 'vue-router'

// page
import Events from '@/views/events/index.vue'
// subpages
import EventsItem from '@/views/events/item/index.vue'
import EventsList from '@/views/events/list/index.vue'

// middlware
import auth from './_middleware/auth'

const route: RouteConfig[] = [
  {
    children: [
      {
        name: 'events.item',
        component: EventsItem,
        path: ':eventId(\\d+)',
        meta: { middleware: [auth] },
      },
      {
        name: 'events.add',
        component: EventsItem,
        path: 'add',
        meta: { middleware: [auth] },
      },
      {
        name: 'events.list',
        component: EventsList,
        path: 'main',
        meta: { middleware: [auth] },
      },
    ],
    component: Events,
    name: 'events',
    path: '/events',
    redirect: '/events/main',
  },
]

export default route
