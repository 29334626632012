























import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import UserMixin from '@/mixins/UserMixin'
// store
import regionModule from '@/store/modules/regions'
import commonStore from '@/store/modules/common'
// components
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
// interfaces
import { IRegionBrief } from '@/store/types/'

@Component({
  components: {
    ButtonWithPlus,
    TextInput,
    NotFoundMessage,
  },
})
export default class RegionsContent extends mixins(NotifyMixin, UserMixin) {

  private regions: IRegionBrief[] = []

  private search = ''

  private get regionsFilter() {
    return this.regions.filter(str => {
      return str.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
    })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Регионы',
    }
  }

  private mounted() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Регионы', path: this.$route.path },
      ])
    }, 10)

    if (!regionModule.regions.length) {
      regionModule.fetchRegions()
        .then((response)=> {
          this.regions = response
          if (!this.isAdmin) {
            this.regions = this.regions.filter(region => region.id === this.userRegionId)
          }
        })
        .catch((err) => {
          this.notifyError(err)
        })
    } else {
      this.regions = regionModule.regions

      if (!this.isAdmin) {
        this.regions = this.regions.filter(region => region.id === this.userRegionId)
      }
    }
  }

  private editRegionData(region: IRegionBrief) {
    this.$router.push( { name: 'region.region', params: { regionID: region.id.toString() } })
  }

  @Watch('search')
  private watchSearch(value: string | null) {
    if (!value && typeof value !== 'string') {
      this.search = ''
    }
  }

}
