import { RouteConfig } from 'vue-router' 

// page
import News from '@/views/news/index.vue'
// subpages
import NewsItem from '@/views/news/item/index.vue'
import NewsList from '@/views/news/list/index.vue'

import auth from './_middleware/auth'

const route: RouteConfig[] = [
  {
    children: [
      {
        name: 'news.item',
        component: NewsItem,
        path: ':newsID(\\d+)',
        meta: { middleware: [auth] },
      },
      {
        name: 'news.add',
        component: NewsItem,
        path: 'add',
        meta: { middleware: [auth] },
      },
      {
        name: 'news.list',
        component: NewsList,
        path: 'main',
        meta: { middleware: [auth] },
      },
    ],
    component: News,
    name: 'news',
    path: '/news',
    redirect: '/news/main',
  },
]

export default route