
































import Component from 'vue-class-component'
import { ValidationProvider } from 'vee-validate'
import { Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
// interfaces
import { IBannerRequest } from '@/store/types/home'

@Component({
  components: {
    FileUploader,
    TextInput,
    ValidationProvider,
  },
})
export default class HomeBanner extends NotifyMixin {

  @Prop({ required: true })
  private form!: IBannerRequest

  @Prop({ default: null })
  private fileName!: string | null

  private formInner: IBannerRequest = {
    caption: '',
    buttonLink: '',
    backgroundId: null,
  }

  private mounted() {
    this.formInner = this.form
  }

  @Watch('formInner', { deep: true })
  watchForm(value: IBannerRequest) {
    this.$emit('update:form', { banner: { ...value } })
  }

  private startLoad() {
    this.$emit('startLoadFile')
  }

  private endLoad() {
    this.$emit('endLoadFile')
  }
}
