














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

// interfaces
import { IPerson } from '@/store/types'

@Component({
  components: {

  },
})
export default class PublicCouncilContent extends Vue {

  @Prop({ default: null })
  readonly people!: IPerson[]

  private editPerson(id: number) {
    this.$router.push({ name: 'council.member', params: { councilMemberId: id.toString() } })
  }

}
