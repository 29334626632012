





























































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { isEmpty } from 'lodash'

// interfaces
import { IEventsList, IEventsResponse } from '@/store/types'
// mixins
import RegionMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import UserMixin from '@/mixins/UserMixin'
// store
import commonStore from '@/store/modules/common'
import eventsModule from '@/store/modules/events'
// components
import Pagination from '@/components/_uikit/Pagination.vue'
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import EventsListContent from '@/components/views/events/EventsListContent.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
import Spinner from '@/components/_uikit/Spinner.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// utils
import { isBeforeDate } from '@/utils/functions'

@Component({
  components: {
    DatePicker,
    EventsListContent,
    Select,
    Pagination,
    NotFoundMessage,
    Spinner,
    TextInput,
  },
})
export default class EventsList extends mixins(RegionMixin, ProjectMixin, NotifyMixin, UserMixin) {

  private loading = false

  private page = 1

  private events: IEventsResponse[] = []

  private regionIdParam = this.$route.query.regionId ? +this.$route.query.regionId : null

  private projectIdParam = this.$route.query.projectId ? +this.$route.query.projectId : null

  private searchParam = this.$route.query.searchParam ? this.$route.query.searchParam.toString() : ''

  private dateStart = this.$route.query.dateStart ? this.$route.query.dateStart.toString() : ''
  private dateEnd = this.$route.query.dateEnd ? this.$route.query.dateEnd.toString() : ''
  private date = [this.dateStart, this.dateEnd]

  private eventsPerPage = 15

  private pageAmount = Math.floor(this.events.length / this.eventsPerPage)

  private addNewEvent() {
    this.$router.push({ name: 'events.add' })
  }

  private getEvents(resetPage = false) {
    this.page = typeof resetPage === 'boolean' && resetPage ? this.page : 1

    if (!this.isAdmin) {
      this.regionIdParam = this.userRegionId
    }

    const { dateStart, dateEnd } = isBeforeDate(this.date[0], this.date[1])
    this.dateStart = dateStart
    this.dateEnd = dateEnd

    const params = {
      page: this.page,
      projectId: this.projectIdParam,
      regionId: this.regionIdParam,
      date1: this.dateStart,
      date2: this.dateEnd,
      search: this.searchParam,
    }

    this.loading = true

    eventsModule.fetchEvents(params)
      .then((response: IEventsList) => {
        this.events = response.data
        this.pageAmount = response.meta.lastPage
      })
      .catch(err => this.notifyError(err))
      .finally(() => {
        this.loading = false
      })
  }

  private changePage() {
    this.getEvents(true)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'События',
    }
  }

  private mounted() {

    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'События', path: this.$route.path },
      ])
    }, 10)
    this.getEvents()
  }

  @Watch('regionIdParam')
  private watchRegionIdParam() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, regionId: this.regionIdParam ? this.regionIdParam.toString() : '' } })
  }

  @Watch('projectIdParam')
  private watchProjectIdParam() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, projectId: this.projectIdParam ? this.projectIdParam.toString() : '' } })
  }

  @Watch('date')
  private watchDate() {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, dateStart: this.dateStart, dateEnd: this.dateEnd } })
  }

  @Watch('searchParam')
  private watchSearchParam(value: string) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, searchParam: this.searchParam } })

    if (value && value.length > 2) {
      this.getEvents()
    } else if (!value) {
      this.getEvents()
    }
  }

  @Watch('$route.query')
  private watchParams() {
    if (isEmpty(this.$route.query)) {
      this.regionIdParam = null
      this.projectIdParam = null
      this.date = ['', '']

      this.getEvents()
    }
  }

}
