import { RouteConfig } from 'vue-router'

import AuthService from '@/views/auth/childs/AuthService.vue'

const route: RouteConfig[] = [{
  component: AuthService,
  name: 'OAuthCallback',
  path: '/callback',
}]

export default route