import Cookies from 'js-cookie'

export const authTokenName = process.env.VUE_APP_TOKEN_NAME || 'auth-token'
export const authUser = process.env.VUE_APP_USER || 'auth-user'

export function getToken () {
  return Cookies.get(authTokenName)
}

export function getUser () {
  return Cookies.get(authUser)
}

export const baseURL = process.env.VUE_APP_BACKEND_API_ENDPOINT || 'https://test.ru/api'
