












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonWithPlus extends Vue {
  @Prop({
    default: 'primary',
    validator (value: string): boolean {
      return !!value.match(/(primary|success|info|error)/)
    },
  })
  readonly skin!: string
}
