import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import { messages } from './vee-validate-ru.json'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  })
})

extend('required', {
  computesRequired: true,
  validate (value) {
    return {
      required: true,
      valid: (!Array.isArray(value) && ['', false, null, undefined].indexOf(value) === -1) || (Array.isArray(value) && value.length !== 0),
    }
  },
})

extend('requiredSelect', {
  computesRequired: true,
  validate (value) {
    return {
      required: true,
      valid: (!Array.isArray(value) && ['', false, null, undefined, 0].indexOf(value) === -1) || (Array.isArray(value) && value.length !== 0),
    }
  },
})

extend('password', {
  message: 'Пароли не совпадают',
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
})
