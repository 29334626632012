

























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
// interfaces
import { IQuoteRequest } from '@/store/types/home'

@Component({
  components: {
    FileUploader,
    TextAreaInput,
    TextInput,
    ValidationProvider,
  },
})
export default class QuoteCard extends Vue {

  @Prop({ required: true })
  private quote!: IQuoteRequest

  @Prop({ default: '' })
  private fileName!: string

  private quoteInner: IQuoteRequest = this.quote

  private deleteQuote() {
    this.$emit('deleteQuote')
  }

  @Watch('quote', { deep: true })
  watchPropQuote(value: IQuoteRequest) {
    this.quoteInner = value
  }

  @Watch('quoteInner', { deep: true })
  watchInnerQuote(value: IQuoteRequest) {
    this.$emit('updateQuote', value)
  }

  private startLoad() {
    this.$emit('startLoad')
  }

  private endLoad() {
    this.$emit('endLoad')
  }
}
