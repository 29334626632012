


















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { formatTime } from '@/utils/functions'

@Component({
  inheritAttrs: false,
})
export default class TimePicker extends Vue {
  @Ref() dialog!: any

  @Prop()
  private value!: string

  @Prop({ default: null })
  private label!: string

  @Prop({ default: null })
  private placeholder!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private disabled!: boolean
  
  private innerValue = ''
  private showPicker = false

  private mounted () {
    this.innerValue = this.value ? formatTime(this.value) : ''
  }

  private changeTime (value: string) {
    this.innerValue = value
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private handleInput (value: string) {
    this.dialog.save(value)
    this.innerValue = value
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
