import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'

import { IMediaList, IMediaListResponse, IMediaRequest, IMediaResponse } from '../types'

@Module({
  dynamic: true,
  name: 'media',
  store,
})
class Media extends VuexModule {
  // ------------------------------ MEDIA ------------------------------ >>
  currentMediaId = -1

  mediaList: IMediaListResponse[] = []

  media: IMediaResponse | null = null

  @Mutation
  setCurrentMediaId(payload: number) {
    this.currentMediaId = payload
  }

  @Mutation 
  setMediaList(payload: IMediaListResponse[]) {
    this.mediaList = payload
  }

  @Mutation
  setMedia(payload: IMediaResponse) {
    this.media = payload
  }

  @Action({ rawError: true }) 
  async fetchMedia(params: any): Promise<IMediaList> {
    const { data } = await api.get(
      '/media',
      { params },
    ) 

    this.setMediaList(data)

    return data
  }

  @Action({ rawError: true }) 
  async fetchMediaItem(): Promise<IMediaResponse> {
    const { data } = await api.get(
      `/media/${this.currentMediaId}`,
    ) 

    this.setMedia(data)

    return data
  }

  @Action({ rawError: true })
  async addMedia(payload: IMediaRequest): Promise<IMediaResponse> {
    const { data } = await api.post(
      '/media',
      payload,
    )

    this.setMedia(data)

    return data
  } 

  @Action({ rawError: true }) 
  async updateMedia(payload: IMediaRequest): Promise<IMediaResponse> {
    const { data } = await api.put(
      `/media/${this.currentMediaId}`,  
      payload,
    )

    this.setMedia(data)

    return data
  }

  @Action({ rawError: true })
  async deleteMedia(): Promise<void> {
    const { data } = await api.delete(
      `/media/${this.currentMediaId}`,
    )

    return data
  } 
}

const mediaModule = getModule(Media)

export default mediaModule
