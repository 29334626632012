
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import Spinner from '@/components/_uikit/Spinner.vue'
import Pagination from '@/components/_uikit/Pagination.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
import PublicCouncilContent from '@/components/views/publicCouncil/PublicCouncilContent.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// interfaces
import { IPersonList, IPerson } from '@/store/types'
import commonStore from '@/store/modules/common'
// utils
import { formatFullName } from '@/utils/functions'
import publicCouncil from '@/store/modules/publicCouncil'

@Component({
  components: {
    Pagination,
    PublicCouncilContent,
    Spinner,
    NotFoundMessage,
    TextInput,
  },
})
export default class PublicCouncilList extends Vue {

  private page = 1

  private people: IPerson[] = []

  private searchParam = this.$route.query.searchParam ? this.$route.query.searchParam.toString() : ''

  private peoplePerPage = 10

  private loading = false

  private pageAmount = Math.floor(this.people.length / this.peoplePerPage)

  private addNewPerson() {
    this.$router.push({ name: 'council.add' })
  }

  private getPeople() {
    this.loading = true
    publicCouncil.fetchPersons({ page: this.page, search: this.searchParam })
      .then((response: IPersonList) => {
        this.people = response.data.map(person => ({ ...person, name: formatFullName(person.name, person.surname, person.patronymic) }))
        this.pageAmount = response.meta.lastPage
      })
      .finally(() => this.loading = false)
  }

  private changePage() {
    this.getPeople()
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Сотрудники',
    }
  }

  private mounted() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Сотрудники', path: this.$route.path },
      ])
    }, 10)
    this.getPeople()
  }

  @Watch('searchParam')
  private watchSearchParam(value: string) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, searchParam: this.searchParam } })

    if (value && value.length > 2) {
      this.page = 1
      this.getPeople()
    } else if (!value) {
      this.page = 1
      this.getPeople()
    }
  }
}
