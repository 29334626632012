











import { Vue, Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// Components
import AuthForm from '@/components/views/auth/AuthForm.vue'

@Component({
  components: {
    AuthForm,
  },
})
export default class Home extends Vue {
  private get backgroundPath () {
    return require('@/assets/images/authBackground.png')
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Авторизация ',
    }
  }
}
