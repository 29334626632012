








import Vue from 'vue'
import Component from 'vue-class-component'

// components
import Tabs from '@/components/_uikit/Tabs.vue'
// interfaces
import { ITab } from '@/store/types'

@Component({
  components: {
    TabsComponent: Tabs,
  },
})
export default class Project extends Vue {
  private tabs: ITab[] = []

  private mounted () {
    this.tabs = [
      {
        name: 'список проектов',
        to: '/projects',
        visible: ['federal'],
      },
      {
        name: 'проекты в регионе',
        to: '/projects/regions',
        visible: ['federal', 'regional'],
      },
    ]
  }
}
