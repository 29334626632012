import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'

import { IHomeRequest, IHomeResponse } from '../types/home'


@Module({
  dynamic: true,
  name: 'home',
  store,
})
class Home extends VuexModule {
  // ------------------------------ DATA ------------------------------ >>
  homePageData: IHomeResponse | null = null
  
  // ------------------------------ MAIN ------------------------------ >>
  @Mutation
  setMainPage(payload: IHomeResponse) {
    this.homePageData = payload
  }

  @Action({ rawError: true })
  async fetchMainPage(): Promise<IHomeResponse> {
    const { data } = await api.get(
      '/main',
    )

    this.setMainPage(data)

    return data
  }

  @Action({ rawError: true })
  async updateMainPage(payload: IHomeRequest): Promise<IHomeResponse> {
    const { data } = await api.post(
      '/main',
      payload,
    )

    this.setMainPage(data)

    return data
  }
}

const homeModule = getModule(Home)

export default homeModule
