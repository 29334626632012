var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('ValidationObserver',{ref:"form",staticClass:"d-flex flex-column justify-space-between",attrs:{"tag":"div"}},[_c('div',{staticClass:"wrrapper_check"},[_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"rules":{required: true, regex: _vm.namePattern, max:255},"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"rule":[],"placeholder":"Фамилия","label":"Фамилия","invalid":errors.length > 0,"error":errors[0],"customMessages":{regex: 'Только буквы и дефис'}},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})]}}],null,false,230443566)}),_c('ValidationProvider',{staticClass:"mx-8",attrs:{"rules":{required: true, regex: _vm.namePattern, max:255},"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Имя","label":"Имя","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,false,473295410)}),_c('ValidationProvider',{attrs:{"rules":{required: true, regex: _vm.patronymicPattern, max:255},"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Отчество","label":"Отчество","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}})]}}],null,false,4198033341)}),_c('ValidationProvider',{staticClass:"ml-8",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Электронная почта","label":"Электронная почта","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,false,1562375289)})],1),_c('ValidationProvider',{staticClass:"d-flex",attrs:{"rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Должность","label":"Должность","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})]}}],null,false,4016336972)}),_c('div',{staticClass:"file-uploader"},[_c('FileUploader',{attrs:{"label":"Фото","initialFileName":_vm.initialFileName,"rules":"required|image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.imageId),callback:function ($$v) {_vm.$set(_vm.form, "imageId", $$v)},expression:"form.imageId"}})],1)],1),_c('ControlButtons',{attrs:{"submitting":_vm.isSubmitting,"isAddMode":_vm.isAddMode},on:{"addControlCallback":_vm.addPerson,"updControlCallback":_vm.saveChanges,"delControlCallback":function($event){return _vm.confirmDelete('персоны', 'сотрудника', _vm.deletePerson)}}}),_c('Confirmation',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }