












import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import SocialMediaItem from '@/components/views/home/SocialMediaItem.vue'
// interfaces
import { ISocialMedia } from '@/store/types/home'
// functions
import { capitalize } from '@/utils/functions'

@Component({
  components: {
    SocialMediaItem,
  },
})
export default class HomeSocialMedia extends NotifyMixin {

  @Prop({ required: true })
  private form!: ISocialMedia[]

  private formInner: ISocialMedia[] = []

  private mounted() {
    this.formInner = this.form
  }

  private capitalize(name: string) {
    return capitalize(name)
  }

  private handleChanges(value: any) {
    this.formInner = (this.form as ISocialMedia[]).map(item => {
      if (item.name.toLowerCase() === value.name.toLowerCase()) return value
      return item
    })
  }

  @Watch('formInner', { deep: true })
  watchForm(value: ISocialMedia[]) {
    this.$emit('update:form', { social: [ ...value ] })
  }
}
