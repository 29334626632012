

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// store
import commonStore from '@/store/modules/common'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FileUploader extends Vue {

  @Prop({ default: '' })
  readonly acceptList!: string

  @Prop({ default: null })
  private fileData!: File

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop()
  private initialFileName!: string | string[]

  @Prop({ default: '' })
  private message!: string

  @Prop({ default: '' })
  private rules!: string

  @Prop({ default: false })
  private multiple!: boolean

  private fileDataInner: File | File[] | null = null

  private created() {
    if (this.initialFileName && typeof this.initialFileName === 'string') {
      this.fileDataInner = new File(['foo'], this.initialFileName)
    }

    if (this.initialFileName && typeof this.initialFileName === 'object') {
      this.fileDataInner = this.initialFileName.map(file => new File(['foo'], file))
    }
  }

  private handleInput (value: File[]) {
    this.fileDataInner = value

    // single file upload
    if (value.length && !this.multiple) {
      this.$emit('startLoad')
      commonStore.fetchFile(value[0])
        .then(response => {
          this.$emit('input', response.id)
          this.$emit('endLoad')
        })
    } else if (!value.length && !this.multiple) {
      this.$emit('input', null)
    }

    // multi file upload
    if (value.length && this.multiple) {
      this.$emit('startLoad')
      commonStore.fetchFiles(value)
        .then(response => {
          const ids = response.map(file => file.id)
          this.$emit('input', ids)
          this.$emit('endLoad')
        })
    } else if (!value.length && this.multiple) {
      this.$emit('input', [])
    }
  }

  @Watch('fileData')
  private watchValue (value: File) {
    this.fileDataInner = value
  }
}
