









import { Vue, Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// Components
import NavigationMenu from '@/components/NavigationMenu.vue'
import ApplicationBar from '@/components/ApplicationBar.vue'
import Snackbar from '@/components/Snackbar.vue'
// store
import SystemModule from '@/store/modules/system'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ApplicationBar,
    NavigationMenu,
    Snackbar,
  },
})
export default class App extends Vue {
  private get user () {
    return AuthModule.user
  }

  private get isBars () {
    return this.$route.path !== '/auth'
  }

  private get loading () {
    return SystemModule.loading
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Авторизация',
      titleTemplate: '%s - Единая Россия',
    }
  }
}
