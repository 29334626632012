var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.form)?_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('VForm',{staticClass:"d-flex flex-column justify-space-between",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"task d-flex flex-column"},[_c('ValidationProvider',{attrs:{"rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Заголовок к задачам","label":"Заголовок к задачам","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.taskCaption),callback:function ($$v) {_vm.$set(_vm.form, "taskCaption", $$v)},expression:"form.taskCaption"}})]}}],null,false,2740348662)}),_vm._l((_vm.form.tasks),function(task,index){return [_c('ValidationProvider',{staticClass:"d-flex",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{staticClass:"mr-6",attrs:{"placeholder":"Задача в регионе","label":"Задача в регионе","invalid":errors.length > 0,"error":errors[0]},model:{value:(task.description),callback:function ($$v) {_vm.$set(task, "description", $$v)},expression:"task.description"}}),(index < _vm.form.tasks.length - 1)?_c('VBtn',{attrs:{"fab":"","outlined":"","max-width":"40","max-height":"40","color":"error"},on:{"click":function () { return _vm.removeTask(index); }}},[_c('VIcon',[_vm._v("mdi-close")])],1):_vm._e(),(index === _vm.form.tasks.length - 1)?_c('VBtn',{attrs:{"fab":"","outlined":"","max-width":"40","max-height":"40","color":"primary"},on:{"click":_vm.addTask}},[_c('VIcon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)})]})],2),_c('div',{staticClass:"tiptap-container mb-6"},[_c('TiptapVuetify',{staticClass:"tiptap",attrs:{"extensions":_vm.extensions,"placeholder":"Описание","label":"Описание","no-resize":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('ValidationProvider',{staticClass:"tiptap__input",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"","label":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,false,690930303)})],1),(_vm.form.contacts.length)?_vm._l((_vm.form.contacts),function(contact,index){return _c('VRow',{key:index},[_c('VCol',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Подпись к контакту","label":"Подпись к контакту","invalid":errors.length > 0,"error":errors[0]},model:{value:(contact.caption),callback:function ($$v) {_vm.$set(contact, "caption", $$v)},expression:"contact.caption"}})]}}],null,true)})],1),_c('VCol',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"rules":{ required: contact.email ? false : true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{directives:[{name:"mask",rawName:"v-mask",value:('+7 ### ### ####'),expression:"'+7 ### ### ####'"}],attrs:{"placeholder":"Телефон","label":"Телефон","invalid":errors.length > 0,"error":errors[0]},model:{value:(contact.phone),callback:function ($$v) {_vm.$set(contact, "phone", $$v)},expression:"contact.phone"}})]}}],null,true)})],1),_c('VCol',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":{ required: contact.phone ? false : true, email: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Электронная почта","label":"Электронная почта","invalid":errors.length > 0,"error":errors[0]},model:{value:(contact.email),callback:function ($$v) {_vm.$set(contact, "email", $$v)},expression:"contact.email"}})]}}],null,true)})],1),_c('VCol',{attrs:{"cols":"1"}},[_c('VBtn',{attrs:{"fab":"","outlined":"","max-width":"40","max-height":"40","color":"error"},on:{"click":function () { return _vm.removeContact(index); }}},[_c('VIcon',[_vm._v("mdi-close")])],1)],1)],1)}):_vm._e(),(_vm.form.contacts.length < 3)?_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('ButtonWithPlus',{on:{"click":_vm.addContact}},[_vm._v("Добавить контакт")])],1)],1):_vm._e(),_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.people,"placeholder":"Координатор","label":"Координатор","item-value":"id","item-text":"name","search":true,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.coordinatorId),callback:function ($$v) {_vm.$set(_vm.form, "coordinatorId", $$v)},expression:"form.coordinatorId"}})]}}],null,false,4012454326)})],1),_c('VCol',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.people,"placeholder":"Председатель","label":"Председатель","item-value":"id","item-text":"name","search":true,"clearable":true,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.chairmanId),callback:function ($$v) {_vm.$set(_vm.form, "chairmanId", $$v)},expression:"form.chairmanId"}})]}}],null,false,605256844)})],1)],1)],2),_c('div',{staticClass:"btn__container"},[_c('VSwitch',{attrs:{"label":"Отображать на сайте","inset":""},model:{value:(_vm.form.published),callback:function ($$v) {_vm.$set(_vm.form, "published", $$v)},expression:"form.published"}}),_c('div',{staticClass:"btn-container"},[_c('ControlButtons',{attrs:{"isAddMode":_vm.isAddMode},on:{"addControlCallback":_vm.addProjectRegion,"updControlCallback":_vm.changeProjectRegion,"delControlCallback":function($event){return _vm.confirmDelete('проекта из региона', 'проект из региона', _vm.deleteProjectRegion)}}})],1),_c('Confirmation',{ref:"confirm"})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }