















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

// interfaces
import { IMediaListResponse } from '@/store/types'


@Component({
  components: {
    
  },
})
export default class MediaListContent extends Vue {

  @Prop()
  private list!: IMediaListResponse[]

  private get media(): IMediaListResponse[] {
    return this.list.map(item => ({
      id: item.id,
      caption: item.caption,
      publishedAt: moment(item.publishedAt).format('DD/MM/YYYY'),
    }))
  }

  private handleEdit(id: number) {
    this.$router.push({ name: 'media.item', params: { mediaId: id.toString() } } )
  }
}
