import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/utils/services/api'
import store from '@/store'
import { IManagersResponse, IManagerRequest, IManagerResponse } from '@/store/types/index'

@Module({
  dynamic: true,
  name: 'managers',
  store,
})
class Manager extends VuexModule {

  // ------------------------------ COMMON ------------------------------ >>

  managers: IManagersResponse[] = []

  @Mutation
  setManagersList(payload: IManagersResponse[]) {
    this.managers = payload
  }

  @Action({ rawError: true })
  async fetchManagersList(): Promise<IManagersResponse[]> {
    const { data } = await api.get(
      '/users',
    )

    this.setManagersList(data)

    return data
  }

  @Action({ rawError: true })
  async resetPassword(payload: IManagerRequest): Promise<IManagerResponse> {
    const { data } = await api.post(
      `/users/${payload.userId}/password`,
    )

    return data
  }
}

const managerModule = getModule(Manager)

export default managerModule
