import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import api from '@/utils/services/api'
import {
  IProjectBrief,
  IProjectResponse,
  IProjectRequest,
  IProjectPublished,
  IProjectRegionBrief,
  IProjectRegionResponse, IProjectRegionRequest,
} from '@/store/types/'

@Module({
  dynamic: true,
  name: 'projects',
  store,
})
class ProjectsModule extends VuexModule {
  // ------------------------------ NON-REGION ------------------------------ >>

  project: IProjectResponse | null = null

  currentProjectId = -1

  projects: IProjectBrief[] = []

  @Mutation
  setProjects(payload: IProjectBrief[]) {
    this.projects = payload
  }

  @Mutation
  setProject(payload: IProjectResponse) {
    this.project = payload
  }

  @Mutation
  setCurrenProjectId(payload: number) {
    this.currentProjectId = payload
  }

  @Mutation
  setProjectPublished(payload: IProjectPublished) {
    const project = this.projects.find(project => project.id === payload.id)
    if (project) project.published = payload.published
  }

  @Action({ rawError: true })
  async createProject(payload: IProjectRequest): Promise<void> {
    const { data } = await api.post(
      '/projects',
      payload,
    )

    return data
  }

  @Action({ rawError: true })
  async fetchProjects(): Promise<IProjectBrief[]> {
    const { data } = await api.get(
      '/projects',
    )

    this.setProjects(data)

    return data
  }

  @Action({ rawError: true })
  async deleteProject(): Promise<void> {
    const { data } = await api.delete(
      `/projects/${this.currentProjectId}`,
    )

    return data
  }

  @Action({ rawError: true })
  async fetchProject(): Promise<IProjectResponse> {
    const { data } = await api.get(
      `/projects/${this.currentProjectId}`,
    )

    this.setProject(data)

    return data
  }

  @Action({ rawError: true })
  async updateProject(payload: IProjectRequest): Promise<IProjectResponse> {
    const { data } = await api.put(
      `/projects/${this.currentProjectId}`,
      payload,
    )

    this.setProject(data)

    return data
  }

  @Action({ rawError: true })
  async publishedProject(id: number): Promise<IProjectPublished> {
    const { data } = await api.post(
      `/projects/${id}/published`,
    )

    if (this.project && this.project.id === id) {
      this.setProject({
        ...this.project,
        published: data.published,
      })
    }

    this.setProjectPublished(data)

    return data
  }
  // ------------------------------ REGION ------------------------------ >>

  projectsRegion: IProjectBrief[] = []

  regionId = 1

  projectRegion: IProjectRegionResponse | null = null

  currentProjectRegionId = -1

  @Mutation
  setRegionId(payload: number) {
    this.regionId = payload
  }

  @Mutation
  setRegionProjects(payload: IProjectBrief[]) {
    this.projectsRegion = payload
  }

  @Mutation
  setRegionProjectsPublished(payload: IProjectPublished) {
    const project = this.projectsRegion.find(project => project.id === payload.id)
    if (project) project.published = payload.published
  }

  @Mutation
  setProjectRegion(payload: IProjectRegionResponse) {
    this.projectRegion = payload
  }

  @Mutation
  setCurrentProjectRegionId(payload: number) {
    this.currentProjectRegionId = payload
  }

  @Action({ rawError: true })
  async fetchRegionProjects(): Promise<IProjectRegionBrief[]> {
    const params = {
      regionId: this.regionId,
    }

    const { data } = await api.get(
      '/region-projects',
      { params },
    )

    this.setRegionProjects(data)

    return data
  }

  @Action({ rawError: true })
  async fetchProjectRegion(): Promise<IProjectRegionResponse> {
    const { data } = await api.get(
      `/region-projects/${this.currentProjectRegionId}`,
    )

    this.setProjectRegion(data)

    return data
  }

  @Action({ rawError: true })
  async createProjectRegion(payload: IProjectRegionRequest): Promise<void> {
    const { data } = await api.post(
      '/region-projects',
      payload,
    )

    return data
  }

  @Action({ rawError: true })
  async updateProjectRegion(payload: IProjectRegionRequest): Promise<IProjectRegionResponse> {
    const { data } = await api.put(
      `/region-projects/${this.currentProjectRegionId}`,
      payload,
    )

    this.setProjectRegion(data)

    return data
  }

  @Action({ rawError: true })
  async deleteProjectRegion(): Promise<void> {
    const { data } = await api.delete(
      `/region-projects/${this.currentProjectRegionId}`,
    )

    return data
  }

  @Action({ rawError: true })
  async publishedProjectRegion(id: number): Promise<IProjectPublished> {
    const { data } = await api.post(
      `/region-projects/${id}/published`,
    )

    if (this.projectRegion && this.projectRegion.id === id) {
      this.setProjectRegion({
        ...this.projectRegion,
        published: data.published,
      })
    }

    this.setRegionProjectsPublished(data)

    return data
  }
}

const projectsModule = getModule(ProjectsModule)

export default projectsModule
