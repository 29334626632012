


































































import Component, { mixins } from 'vue-class-component'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import { required, regex, max } from 'vee-validate/dist/rules'
extend('required', required)
extend('regex', regex)
extend('max', max)

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'
// store
import councilModule from '@/store/modules/publicCouncil'
// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// interfaces
import { IPerson, IPersonBrief } from '@/store/types'
import ControlButtons from '@/components/ControlButtons.vue'
import commonStore from '@/store/modules/common'

@Component({
  components: {
    TextInput,
    FileUploader,
    ValidationObserver,
    ValidationProvider,
    ControlButtons,
    Confirmation,
  },
})
export default class PublicCouncilMember extends mixins(NotifyMixin, ConfirmationMixin) {

  private isValid = false

  private isLoading = true

  private isSubmitting = false

  private form: IPersonBrief|null = null

  private id = +this.$route.params.councilMemberId

  private initialFileName: string | undefined = undefined

  private isAddMode = this.$route.name === 'council.add'

  // eslint-disable-next-line
  private namePattern = new RegExp(/^[а-яА-Я\-]+$/)

  // eslint-disable-next-line
  private patronymicPattern = new RegExp(/^[а-яА-Я\-]+(\s[а-яА-Я\-]+)?$/)

  private metaInfo (): MetaInfo {
    return {
      title: this.isAddMode ? 'Добавление сотрудника' : 'Редактирование сотрудника',
    }
  }

  private updateLogicMounted() {
    commonStore.setBreadcrumbs([
      { name: 'Сотрудники', path: 'main' },
      { name: 'Редактирование сотрудника', path: this.$route.path },
    ])

    if (!councilModule.person || this.id !== councilModule.currentCouncilPerson) {
      councilModule.setCurrentCouncilPerson(this.id)
      councilModule.fetchPublicCouncilPerson(this.id)
        .then((response) => {
          this.form = this.transformData(response)
          this.initialFileName = response.image ? response.image?.originalName : undefined
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoading = false)
    } else {
      this.form = this.transformData(councilModule.person as IPerson)
      this.initialFileName = councilModule.person.image ? councilModule.person.image.originalName : ''
      this.isLoading = false
    }
  }

  private addLogicMounted() {
    commonStore.setBreadcrumbs([
      { name: 'Сотрудники', path: 'main' },
      { name: 'Добавление сотрудника', path: this.$route.path },
    ])

    this.isLoading = false
    this.initialFileName = undefined
    this.form = {
      surname: '',
      name: '',
      patronymic: '',
      email: '',
      position: '',
      imageId: null,
    }
  }

  private mounted() {
    if (this.isAddMode) {
      this.addLogicMounted()
    } else {
      this.updateLogicMounted()
    }
  }

  private transformData(data: IPerson): IPersonBrief {
    return {
      surname: data.surname,
      name: data.name,
      patronymic: data.patronymic,
      email: data.email,
      position: data.position,
      imageId: data.image ? data.image.id : null,
    }
  }

  private deletePerson() {
    this.isSubmitting = true
    councilModule.deletePerson()
      .then(() => {
        commonStore.getPeople({ perPage: -1 })
        this.notifySuccess('Пользователь успешно удалён!')
        setTimeout(() => {
          this.$router.push({ name: 'council' })
        }, 500)
      })
      .catch((err) => {
        this.notifyError(err)
      })
      .finally(() => {
        this.isSubmitting = false
      })
  }

  private addPerson() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form) {
            this.isSubmitting = true
            councilModule.addNewPerson(this.form)
              .then(() => {
                commonStore.getPeople({ perPage: -1 })
                this.notifySuccess('Данные успешно добавлены!')
                setTimeout(() => {
                  this.$router.push({ name: 'council' })
                }, 500)
              })
              .catch((err) => this.notifyError(err))
              .finally(() => {
                this.isSubmitting = false
              })
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private saveChanges() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form) {
            this.isSubmitting = true
            councilModule.updateCouncilMember(this.form)
              .then(() => {
                commonStore.getPeople({ perPage: -1 })
                this.$router.push({ name: 'council.list' })
                this.notifySuccess('Данные успешно изменены!')
              })
              .catch((err) => this.notifyError(err))
              .finally(() => {
                this.isSubmitting = false
              })
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }
}
