import { DateTime } from 'luxon'
import moment from 'moment'

export function capitalize (value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function formatDate (date: string, mask = 'dd.MM.yyyy') {
  return capitalize(DateTime.fromSQL(date).toFormat(mask))
}

export function formatTime (date: string, mask = 'HH:mm') {
  return capitalize(DateTime.fromSQL(date).toFormat(mask))
}

export function formatFullName(name: string, surname: string, patronymic?: string) {
  if (patronymic) {
    return `${capitalize(surname)} ${capitalize(name)} ${capitalize(patronymic)}`
  }
  return `${capitalize(surname)} ${capitalize(name)}`
}

export function isBeforeDate(date1: string, date2: string) {
  let dateStart = ''
  let dateEnd = ''

  if (date1 && date2) {
    if (moment(date1).isBefore(date2)) {
      dateStart = date1
      dateEnd = date2
    } else {
      dateStart = date2
      dateEnd = date1
    }
  }

  return { dateStart, dateEnd }
}