















































































































































import Component, { mixins } from 'vue-class-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import RegionMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
import UserMixin from '@/mixins/UserMixin'
// components
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import InputChips from '@/components/_uikit/controls/InputChips.vue'
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// interfaces
import { IMediaRequest, IMediaResponse } from '@/store/types'
import ControlButtons from '@/components/ControlButtons.vue'
// store
import commonStore from '@/store/modules/common'
import mediaModule from '@/store/modules/media'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'

@Component({
  components: {
    TextInput,
    TextAreaInput,
    DatePicker,
    Select,
    FileUploader,
    ControlButtons,
    ValidationObserver,
    ValidationProvider,
    InputChips,
    ButtonWithPlus,
    Confirmation,
  },
})
export default class MediaItem extends mixins(NotifyMixin, RegionMixin, ProjectMixin, ConfirmationMixin, UserMixin) {

  private isValid = false

  private isLoading = true

  private isSubmitting = false

  private id = +this.$route.params.mediaId

  private previewFileName: string | null = null
  private mediaFileName: string[] | null = null

  private get isAddMode() {
    return this.$route.name === 'media.add'
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.isAddMode ? 'Добавление медиаматериала' : 'Редактирование медиаматериала',
    }
  }

  private newMedia() {
    commonStore.setBreadcrumbs([
      { name: 'Медиаматериалы', path: 'main' },
      { name: 'Добавление медиаматериала', path: this.$route.path },
    ])

    this.isLoading = false

    this.previewFileName = ''
    this.mediaFileName = []
  }

  private updateMedia() {
    commonStore.setBreadcrumbs([
      { name: 'Медиаматериалы', path: 'main' },
      { name: 'Редактирование медиаматериала', path: this.$route.path },
    ])

    if (!mediaModule.media || this.id !== mediaModule.currentMediaId) {
      mediaModule.setCurrentMediaId(this.id)
      mediaModule.fetchMediaItem()
        .then((response: IMediaResponse) => {
          this.form = this.transformData(response)
          this.mediaFileName = response.images.map(image => image.originalName)
          this.previewFileName = response.preview ? response.preview.originalName : null
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoading = false)
    } else {
      this.form = this.transformData(mediaModule.media)
      this.mediaFileName = mediaModule.media.images.map(image => image.originalName)
      this.previewFileName = mediaModule.media.preview ? mediaModule.media.preview.originalName : null
      this.isLoading = false
    }
  }

  private form: IMediaRequest = {
    caption: '',
    publishedAt: '',
    type: 'image',
    regionId: null,
    projectId: null,
    images: [{
      id: null,
      description: '',
    }],
    tags: [],
    previewId: null,
    videoLink: '',
  }

  private transformData(data: IMediaResponse): IMediaRequest {
    return {
      caption: data.caption,
      publishedAt: data.publishedAt,
      regionId: data.regionId,
      projectId: data.projectId,
      type: data.type,
      previewId: data.preview ? data.preview.id : null,
      videoLink: data.videoLink,
      tags: data.tags,
      images: data.images ? data.images.map(image => ({
        id: image.id,
        description: image.description,
      })) : [],
    }
  }

  private mounted() {
    if (this.isAddMode && !this.isAdmin) {
      this.form.regionId = this.userRegionId
    }
    this.isAddMode ? this.newMedia() : this.updateMedia()
  }

  private updateTags(value: string[]) {
    if (this.form) {
      this.form.tags = value
    }
  }

  private addMediaFile() {
    if (this.form) {
      this.form.images.push({
        id: null,
        description: '',
      })
    }
  }

  private removeMediaFile(index: number) {
    if (this.form) {
      this.form.images = this.form.images.filter((image, idx) => idx !== index)
    }
  }

  private changeType() {
    if (this.form.type === 'video') {
      this.form.images = []
      this.mediaFileName = []
    } else {
      this.form.videoLink = ''
      this.form.images = [{
        id: null,
        description: '',
      }]
    }
  }

  private removeMedia() {
    this.isSubmitting = true
    mediaModule.deleteMedia()
      .then(() => {
        this.$router.push({ name: 'media' })
        this.notifySuccess('Медиаматериал успешно удален!')
      })
      .catch(err => this.notifyError(err))
      .finally(() => this.isSubmitting = false)
  }

  private changeMedia() {

    const form: any = this.$refs.form

    if (this.form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.isSubmitting = true
            mediaModule.updateMedia(this.form)
              .then(() => {
                this.$router.push({ name: 'media.list' })
                this.notifySuccess('Событие успешно изменено!')
              })
              .catch(err => this.notifyError(err))
              .finally(() => this.isSubmitting = false)
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }
  }

  private addMedia() {

    const form: any = this.$refs.form

    if (this.form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.isSubmitting = true
            mediaModule.addMedia(this.form)
              .then(() => {
                this.notifySuccess('Медиаматериал успешно создан!')
                this.$router.push({ name: 'media' })
              })
              .catch(err => this.notifyError(err))
              .finally(() => this.isSubmitting = false)
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }
  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }
}
