var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.form)?_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('VForm',{staticClass:"d-flex flex-column justify-space-between",attrs:{"isValid":_vm.isValid}},[_c('div',{staticClass:"form__wrapper"},[_c('div',{staticClass:"row"},[_c('VRadioGroup',{attrs:{"row":""},on:{"change":_vm.changeType},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Выберите тип:")])]},proxy:true}],null,false,1834275693),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('VRadio',{attrs:{"value":"image"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Галерея")])]},proxy:true}],null,false,2414850423)}),_c('VRadio',{attrs:{"value":"video"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Видео")])]},proxy:true}],null,false,1068294677)})],1)],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"rule":[],"placeholder":"Название","label":"Название","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}})]}}],null,false,564315781)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1 date",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"placeholder":"Дата","label":"Дата","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.publishedAt),callback:function ($$v) {_vm.$set(_vm.form, "publishedAt", $$v)},expression:"form.publishedAt"}})]}}],null,false,4252000063)}),_c('ValidationProvider',{staticClass:"select",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{staticClass:"mx-6",attrs:{"options":_vm.regions,"disabled":!_vm.isAdmin,"placeholder":"Регион","label":"Регион","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.regionId),callback:function ($$v) {_vm.$set(_vm.form, "regionId", $$v)},expression:"form.regionId"}})]}}],null,false,2944501437)}),_c('ValidationProvider',{staticClass:"select",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.projects,"placeholder":"Проект","label":"Проект","item-value":"id","item-text":"name","invalid":errors.length > 0,"error":errors[0],"clearable":true},model:{value:(_vm.form.projectId),callback:function ($$v) {_vm.$set(_vm.form, "projectId", $$v)},expression:"form.projectId"}})]}}],null,false,3151820929)})],1),(_vm.form.type === 'image')?_c('div',{staticClass:"row mb-3"},[_c('FileUploader',{attrs:{"label":"Превью","placeholder":"Превью","initialFileName":_vm.previewFileName,"rules":"image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.previewId),callback:function ($$v) {_vm.$set(_vm.form, "previewId", $$v)},expression:"form.previewId"}})],1):_c('div',{staticClass:"row mb-3"},[_c('FileUploader',{attrs:{"label":"Превью","placeholder":"Превью","initialFileName":_vm.previewFileName,"rules":"required|image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.previewId),callback:function ($$v) {_vm.$set(_vm.form, "previewId", $$v)},expression:"form.previewId"}})],1),(_vm.form.type === 'video')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"rule":[],"placeholder":"Ссылка на видеотрансляцию","label":"Ссылка на видеотрансляцию","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.videoLink),callback:function ($$v) {_vm.$set(_vm.form, "videoLink", $$v)},expression:"form.videoLink"}})]}}],null,false,1965187994)})],1):_vm._e(),(_vm.form.type === 'image')?[_vm._l((_vm.form.images),function(image,index){return _c('div',{key:index,staticClass:"row mb-3"},[_c('FileUploader',{staticClass:"file-uploader",attrs:{"label":"Медифайл","placeholder":"Медифайл","initialFileName":_vm.mediaFileName[index],"rules":"required|image|size:5120","message":"Размер файла не более 5 Мб. *.jpg, *.png, *.jpeg"},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(image.id),callback:function ($$v) {_vm.$set(image, "id", $$v)},expression:"image.id"}}),_c('ValidationProvider',{staticClass:"fg-1 mx-6",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Описание","label":"Описание","invalid":errors.length > 0,"error":errors[0]},model:{value:(image.description),callback:function ($$v) {_vm.$set(image, "description", $$v)},expression:"image.description"}})]}}],null,true)}),(_vm.form.images.length > 1)?_c('VBtn',_vm._b({attrs:{"color":"red","outlined":"","prepend":""},on:{"click":function($event){return _vm.removeMediaFile(index)}}},'VBtn',_vm.$attrs,false),[_c('VIcon',{staticClass:"mr-3"},[_vm._v("mdi-minus")]),_vm._v("Удалить медиафайл")],1):_vm._e()],1)}),_c('div',{staticClass:"row mt-3"},[_c('ButtonWithPlus',{on:{"click":_vm.addMediaFile}},[_vm._v("Добавить еще медиаматериал")])],1)]:_vm._e()],2),_c('ControlButtons',{staticClass:"mt-9",attrs:{"submitting":_vm.isSubmitting,"isAddMode":_vm.isAddMode},on:{"addControlCallback":_vm.addMedia,"updControlCallback":_vm.changeMedia,"delControlCallback":function($event){return _vm.confirmDelete('медиаматериала', 'медиаматериал', _vm.removeMedia)}}}),_c('Confirmation',{ref:"confirm"})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }