









































import Component from 'vue-class-component'
import { ValidationProvider } from 'vee-validate'
import { Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Snackbar from '@/components/Snackbar.vue'
// interfaces
import { IMain } from '@/store/types/home'

@Component({
  components: {
    Snackbar,
    TextInput,
    ValidationProvider,
  },
})
export default class HomeEditors extends NotifyMixin {

  @Prop({ required: true })
  private form!: IMain

  private formInner: IMain = {
    subtitle: '',
    numberOfObjects: null,
    numberOfProjects: null,
    numberOfRegions: null,
  }

  private mounted() {
    this.formInner = this.form
  }

  @Watch('formInner', { deep: true })
  watchForm(value: IMain) {
    this.$emit('update:form', { main: { ...value } })
  }
}
