var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && _vm.form)?_c('ValidationObserver',{ref:"form",staticClass:"wrapper__main d-flex flex-column justify-space-between",attrs:{"tag":"div"}},[_c('VForm',{attrs:{"isValid":_vm.isValid}},[_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Цитата","label":"Цитата","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.quote),callback:function ($$v) {_vm.$set(_vm.form, "quote", $$v)},expression:"form.quote"}})]}}],null,false,1846433649)}),_c('ValidationProvider',{staticClass:"fg-1",attrs:{"rules":"max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Автор","label":"Автор","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.author),callback:function ($$v) {_vm.$set(_vm.form, "author", $$v)},expression:"form.author"}})]}}],null,false,1422816574)}),_c('ValidationProvider',{staticClass:"fg-1 mb-6",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TiptapVuetify',{attrs:{"extensions":_vm.extensions,"placeholder":"Описание","label":"Описание","rule":[],"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,false,1616184252)}),_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.people,"placeholder":"Ответственное лицо","label":"Ответственное лицо","item-text":"name","item-value":"id","search":true,"clearable":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.responsibleId),callback:function ($$v) {_vm.$set(_vm.form, "responsibleId", $$v)},expression:"form.responsibleId"}})]}}],null,false,723093901)}),_c('div',{staticClass:"file-uploader mr-6"},[_c('FileUploader',{attrs:{"label":"Герб","placeholder":"Герб","rules":"required|ext:png,svg","message":"*.svg, .png","initialFileName":_vm.emblemFileName},on:{"startLoad":_vm.startLoad,"endLoad":_vm.endLoad},model:{value:(_vm.form.emblemId),callback:function ($$v) {_vm.$set(_vm.form, "emblemId", $$v)},expression:"form.emblemId"}})],1)],1),_c('div',{staticClass:"btn"},[_c('VBtn',{attrs:{"x-large":"","color":"primary","disabled":_vm.isSubmitting},on:{"click":_vm.updateRegion}},[_vm._v("Сохранить Изменения")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }