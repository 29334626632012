



































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    TextInput,
  },
  inheritAttrs: false,
})
export default class DateInput extends Vue {
  @Ref() menu!: any

  @Prop()
  private value!: string | string[]

  @Prop({ default: null })
  private label!: string

  @Prop({ default: null })
  private max!: string

  @Prop({ default: '1950-01-01' })
  private min!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private selectYearFirst!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private range!: boolean

  @Prop({ default: false })
  private clearable!: boolean

  private innerValue: string | string[] = ''
  private activePicker: 'DATE' | 'MONTH' | 'YEAR' = 'DATE'
  private showPicker = false

  private get formattedDate () {
    if (this.innerValue && typeof this.innerValue === 'string')
      return this.innerValue ? formatDate(this.innerValue) : ''
    return this.innerValue && typeof this.innerValue === 'object' ? this.innerValue.map(value => value ? formatDate(value) : null).join(' - ').replace(/^\s-\s$/m, '') : []
  }

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string) {
    this.menu.save(value)
    this.innerValue = value
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private clearDate () {
    this.menu.save(['', ''])
    this.innerValue = ['', '']
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  @Watch('showPicker')
  private watchShowPicker (value: boolean) {
    if (value && this.selectYearFirst) {
      setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
