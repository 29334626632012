


















import { Component, Prop } from 'vue-property-decorator'

// types
import { ITab } from '@/store/types'
// mixins
import AuthMixin from '@/mixins/UserMixin'

@Component
export default class Tabs extends AuthMixin {

  @Prop({ required: true })
  private tabs!: ITab[]

  private activeTab = ''
}
