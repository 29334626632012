







































import Component from 'vue-class-component'
import { ValidationObserver } from 'vee-validate'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import homeModule from '@/store/modules/home'
import commonStore from '@/store/modules/common'
// components
import Spinner from '@/components/_uikit/Spinner.vue'
// interfaces
import { IHomeRequest, IHomeResponse, THomePiece } from '@/store/types/home'
import { ITab } from '@/store/types'

import HomeShowBlock from './showBlock/index.vue'
import HomeBanner from './banner/index.vue'
import HomeSocialMedia from './socialMedia/index.vue'
import HomeQuotes from './quotes/index.vue'
import HomeEditors from './editors/index.vue'

@Component({
  components: {
    ValidationObserver,
    HomeShowBlock,
    HomeBanner,
    HomeSocialMedia,
    HomeQuotes,
    HomeEditors,
    Spinner,
  },
})
export default class Home extends NotifyMixin {

  private isLoaded = false
  private isSubmitting = false

  private activeTab = null

  private form: IHomeRequest | null = null
  private bannerFileName: string | null = null
  private quotesFileName: string[] | null = null

  private tabs: ITab[] = [
    {
      name: 'главная страница',
      to: '/main',
    },
    {
      name: 'отображение блоков',
      to: '/main/show-block',
    },
    {
      name: 'баннер',
      to: '/main/banner',
    },
    {
      name: 'Цитаты',
      to: '/main/quotes',
    },
    {
      name: 'Социальные сети',
      to: '/main/social-media',
    },
  ]

  private metaInfo (): MetaInfo {
    return {
      title: 'Главная',
    }
  }

  private mounted () {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Главная', path: this.$route.path },
      ])
    }, 10)

    if (!homeModule.homePageData) {
      homeModule.fetchMainPage()
        .then((data) => {
          this.form = this.transformData(data)
          this.bannerFileName = data.banner.background.originalName
          this.quotesFileName = data.quotes.map(quote => quote.photo.originalName)
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoaded = true)
    } else {
      this.isLoaded = true
      this.form = this.transformData(homeModule.homePageData)
      this.bannerFileName = homeModule.homePageData.banner.background.originalName
      this.quotesFileName = homeModule.homePageData.quotes.map(quote => quote.photo.originalName)
    }
  }

  private handleForm(value: THomePiece) {
    this.form = {
      ...this.form,
      ...value,
    } as IHomeRequest
  }

  private saveChanges() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form) {
            homeModule.updateMainPage(this.form)
              .then((response: IHomeResponse) => {
                this.notifySuccess('Данные успешно изменены')
                this.bannerFileName = response.banner.background.originalName
                this.quotesFileName = response.quotes.map(quote => quote.photo.originalName)
                homeModule.setMainPage(response)
              })
              .catch((err) => {
                this.notifyError(err)
              })
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private transformData(data: IHomeResponse): IHomeRequest {
    return {
      main: {
        subtitle: data.main.subtitle,
        numberOfObjects: data.main.numberOfObjects,
        numberOfProjects: data.main.numberOfProjects,
        numberOfRegions: data.main.numberOfRegions,
      },
      displayBlocks: {
        projectMap: data.displayBlocks.projectMap,
        listOfProjects: data.displayBlocks.listOfProjects,
        news: data.displayBlocks.news,
        events: data.displayBlocks.events,
        bannerNationalProjects: data.displayBlocks.bannerNationalProjects,
        quotes: data.displayBlocks.quotes,
        mediaMaterials: data.displayBlocks.mediaMaterials,
      },
      banner: {
        caption: data.banner.caption,
        buttonLink: data.banner.buttonLink,
        backgroundId: data.banner.background.id,
      },
      social: data.social,
      quotes: data.quotes.map(quote => ({
        author: quote.author,
        content: quote.content,
        position: quote.position,
        photoId: quote.photo.id,
        published: quote.published,
      })),
    }
  }

  private startLoadFile() {
    this.isSubmitting = true
  }

  private endLoadFile() {
    this.isSubmitting = false
  }
}
