





















import moment from 'moment'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { IEventPublished, IEventsResponse } from '@/store/types/index'
import eventsModule from '@/store/modules/events'

@Component({
  components: {
  },
})
export default class EventsListContent extends NotifyMixin {
  @Prop({ required: true })
  private list!: IEventsResponse[]

  private get events(): IEventsResponse[] {
    return this.list.map(item => ({
      id: item.id,
      caption: item.caption,
      published: item.published,
      publishedAt: moment(item.publishedAt).format('DD/MM/YYYY'),
      startDate: moment(item.startDate).format('DD/MM/YYYY'),
      endDate: moment(item.endDate).format('DD/MM/YYYY'),
    }))
  }

  private editEvent(item: IEventsResponse) {
    this.$router.push({ name: 'events.item', params: { eventId: item.id.toString() } })
  }

  private changePublished(item: IEventsResponse) {

    eventsModule.publishedEvent(item.id)
      .then((response: IEventPublished) => {
        const message = response.published ? 'Событие опубликовано' : 'Событие снято с публикации'
        this.notifyInfo(message)
      })
      .catch(() => {
        this.notifyError('Что-то пошло не так, повторите попытку позже')
      })
  }
}
