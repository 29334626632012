

































import Component, { mixins } from 'vue-class-component'
import { Watch, Ref } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import RegionMixin from '@/mixins/RegionMixin'
// store
import managerModule from '@/store/modules/managers'
import commonStore from '@/store/modules/common'
// components
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotFoundMessage from '@/components/_uikit/NotFoundMessage.vue'
import Spinner from '@/components/_uikit/Spinner.vue'
import ShowPassword from '@/components/modals/ShowPassword.vue'
// interfaces
import { IManagersBrief, IManagersResponse, IManagerResponse } from '@/store/types/'

@Component({
  components: {
    ButtonWithPlus,
    TextInput,
    NotFoundMessage,
    Spinner,
    ShowPassword,
  },
})
export default class ManagersList extends mixins(NotifyMixin, RegionMixin) {

  @Ref() private showPassword!: ShowPassword
  
  private get managersList(): IManagersBrief[] {
    if (this.regions && this.regions.length) {
      return this.managers.map((manager: IManagersResponse) => {
        const findRegion = this.regions.find(region => region.id === manager.regionId)
        return {
          ...manager,
          regionName: findRegion ? findRegion.name : '',
        }
      })
    }
    return []
  }

  private loading = false

  private managers: IManagersResponse[] = []

  private search = ''

  private get managersFilter() {
    return this.managersList.filter(str => {
      return str.regionName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
    })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Региональные менеджеры',
    }
  }

  private mounted() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Региональные менеджеры', path: this.$route.path },
      ])
    }, 10)

    if (!managerModule.managers.length) {
      this.loading = true
      managerModule.fetchManagersList()
        .then((response: IManagersResponse[]) => {
          this.managers = response
        })
        .catch((err) => {
          this.notifyError(err)
        })
        .finally(() => this.loading = false)
    } else {
      this.managers = managerModule.managers
    }
  }

  private editRegionData(region: IManagersResponse) {
    this.$router.push( { name: 'region.manager', params: { regionID: region.id.toString() } })
  }

  private resetPassword(id: number) {
    managerModule.resetPassword({ userId: id })
      .then((response: IManagerResponse) => {
        this.showModal(response.password, () => {
          navigator.clipboard.writeText(response.password)
          this.notifyInfo('Пароль скопирован в буфер обмена')
        })
      })
      .catch((err) => {
        this.notifyError(err)
      })
  }

  private showModal(password: string, callback: (...args: any[]) => void): void {
    this.showPassword.open(password)
      .then(() => callback())
      .catch(() => {return})
  }

  @Watch('search')
  private watchSearch(value: string | null) {
    if (!value && typeof value !== 'string') {
      this.search = ''
    }
  }

}
