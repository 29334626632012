import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/utils/services/api'
import store from '@/store'
import { IRegionResponse, IRegionBrief, IRegionRequest } from '@/store/types/index'

@Module({
  dynamic: true,
  name: 'regions',
  store,
})
class Region extends VuexModule {
  // ------------------------------ COMMON ------------------------------ >>
  currentRegionID = -1

  isRegionEdit = false

  region: IRegionResponse | null = null

  regions: IRegionBrief[] = []

  @Mutation
  setCurrentRegionID(id: number) {
    this.currentRegionID = id
  }

  @Mutation
  setRegionsData(payload: IRegionBrief[]) {
    this.regions = payload
  }

  @Mutation
  setRegionData(payload: IRegionResponse) {
    this.region = payload
  }

  @Mutation
  setEditMode(isEdit: boolean) {
    this.isRegionEdit = isEdit
  }

  @Action({ rawError: true })
  async fetchRegions(): Promise<IRegionBrief[]> {
    const { data } = await api.get(
      '/regions',
    )

    this.setRegionsData(data)

    return data
  }

  @Action({ rawError: true })
  async fetchRegionById(id: number): Promise<IRegionResponse> {
    const { data } = await api.get(
      `/regions/${id}`,
    )

    this.setRegionData(data)

    return data
  }

  @Action({ rawError: true })
  async updateRegion(payload: IRegionRequest): Promise<IRegionResponse> {
    const { data } = await api.put(
      `/regions/${this.currentRegionID}`,
      payload,
    )

    this.setRegionData(data)

    return data
  }
}

const regionModule = getModule(Region)

export default regionModule
