import { RouteConfig } from 'vue-router'

// pages
import Managers from '@/views/managers/index.vue'
// subpages
import ManagerList from '@/views/managers/list/index.vue'
import auth from '@/router/_middleware/auth'
import admin from '@/router/_middleware/admin'

const route: RouteConfig[] = [
  {
    children: [
      {
        component: ManagerList,
        name: 'managers.list',
        path: 'main',
        meta: { middleware: [auth, admin] },
      },
    ],
    component: Managers,
    name: 'managers',
    path: '/managers',
    redirect: '/managers/main',
  },
]

export default route
