import { RouteConfig } from 'vue-router'

// page
import PublicCouncil from '@/views/publicCouncil/index.vue'
// subpages
import PublicCouncilMember from '@/views/publicCouncil/member/index.vue'
import PublicCouncilList from '@/views/publicCouncil/list/index.vue'
// middleware
import auth from '@/router/_middleware/auth'
import admin from '@/router/_middleware/admin'

const route: RouteConfig[] = [
  {
    children: [
      {
        name: 'council.member',
        component: PublicCouncilMember,
        path: ':councilMemberId(\\d+)',
        meta: { middleware: [auth, admin] },
      },
      {
        name: 'council.add',
        component: PublicCouncilMember,
        path: 'add',
        meta: { middleware: [auth, admin] },
      },
      {
        name: 'council.list',
        component: PublicCouncilList,
        path: 'main',
        meta: { middleware: [auth, admin] },
      },
    ],
    component: PublicCouncil,
    name: 'council',
    path: '/public-council',
    redirect: '/public-council/main',
  },
]

export default route
