import { RouteConfig } from 'vue-router'

import Auth from '@/views/auth/index.vue'

const route: RouteConfig = {
  component: Auth,
  name: 'Auth',
  path: '/auth',
}

export default route
