












import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'
// components
import Breadcrumbs from '@/components/_uikit/Breadcrumbs.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// modules
import commonStore from '@/store/modules/common'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Breadcrumbs,
    Confirmation,
  },
})
export default class ApplicationBar extends mixins(NotifyMixin, ConfirmationMixin) {
  
  private get breadcrumbs() {
    return commonStore.breadcrumbs
  }

  private logout() {
    AuthModule.logout()
      .then(() => {
        this.$router.push({ name: 'Auth' })
        commonStore.clearBreadcrumbs()
      })
      .catch(err => this.notifyError(err))
  }

  @Watch('$route.path')
  private watchRoute() {
    commonStore.clearBreadcrumbs()
  }
}
