



















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// functions
import { capitalize } from '@/utils/functions'

@Component({
  components: {
    TextInput,
    ValidationProvider,
  },
})
export default class SocialMediaItem extends Vue {
  
  @Prop({ default: '' })
  readonly name!: string

  @Prop({ default: '' })
  private link!: string

  @Prop({ default: false })
  private published!: boolean

  private socialMediaState = false
  private linkInner = ''

  private capitalize(name: string) {
    return capitalize(name)
  }

  private mounted() {
    this.linkInner = this.link
    this.socialMediaState = this.published
  }

  @Watch('socialMediaState')
  watchSocialMediaState(value: boolean) {
    this.$emit('changed', { name: this.name, published: value, link: this.linkInner })
  }

  @Watch('linkInner')
  watchLinkState(value: string){
    if (!value) {
      this.socialMediaState = false
    }
    this.$emit('changed', { name: this.name, published: this.socialMediaState, link: value })
  }
}
