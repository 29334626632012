









import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {

  @Prop({ default: 0 })
  private pageAmount!: number

  @Prop({ default: 0 })
  private totalVisible!: number

  private pageInner = 1

  private handleInput (value: any) {
    this.pageInner = value
    this.$emit('input', this.pageInner)
  }
}
