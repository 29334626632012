import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: '#8c9eff',
        error: '#FF5252',
        primary: '#1976D2',
        secondary: '#BBDEFB',
        textColor: '#333333',
        warning: '#E40520',
      },
    },
  },
})
