


import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// Store
import AuthModule from '@/store/modules/auth'
// Types
import { IUserType } from '@/store/types'
// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'

@Component
export default class AuthForm extends mixins(NotifyMixin) {
  private created () {
    AuthModule.loginWithProfileER(this.$route.query.code.toString())
      .then(() => {
        localStorage.setItem('auth-token', (AuthModule.oAuthUser as IUserType).token)
        localStorage.setItem('auth-user', JSON.stringify(AuthModule.oAuthUser as IUserType))
        this.$router.push('/main')
      })
      .catch(() => {
        this.$router.push('/auth')
        this.notifyError('Что-то пошло не так. Перезагрузите страницу и попробуйте еще раз.')
      })
  }
}
