






import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

// components
import ProjectsItemFormRegion from '@/components/views/projects/ProjectsItemFormRegion.vue'
import ProjectsItemForm from '@/components/views/projects/ProjectsItemForm.vue'

// interfaces
// import { IProjects } from '@/store/types/projects'
@Component({
  components: {
    ProjectsItemForm,
    ProjectsItemFormRegion,
  },
})
export default class ProjectsEdit extends Vue {

  private isRegion = this.$route.name === 'projects.region.add' || this.$route.name === 'projects.region.edit'

  private get routeName() {
    return this.$route.name
  }

  @Watch('routeName')
  watchRoute(value: any) {
    this.isRegion = value === 'projects.region.item'
  }
}
