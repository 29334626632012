



















































































































































import Component, { mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import { TiptapVuetify, Bold, Paragraph, Link } from 'tiptap-vuetify'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PeopleMixin from '@/mixins/PeopleMixin'
import RegionsMixin from '@/mixins/RegionMixin'
import ProjectMixin from '@/mixins/ProjectMixin'
// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import ButtonWithPlus from '@/components/_uikit/buttons/ButtonWithPlus.vue'
import Chip from '@/components/_uikit/Chip.vue'
import ControlButtons from '@/components/ControlButtons.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// store
import { IProjectRegionRequest, IProjectRegionResponse } from '@/store/types'
import projectsModule from '@/store/modules/projects'
import commonStore from '@/store/modules/common'
import ConfirmationMixin from '@/mixins/ConfirmationMixin'

@Component({
  components: {
    ButtonWithPlus,
    Chip,
    Select,
    TextAreaInput,
    TextInput,
    ControlButtons,
    ValidationObserver,
    ValidationProvider,
    Confirmation,
    TiptapVuetify,
  },
})
export default class ProjectsItemFormRegion extends mixins(NotifyMixin, PeopleMixin, RegionsMixin, ProjectMixin, ConfirmationMixin) {

  private extensions = [
    Bold,
    Paragraph,
    Link,
  ]

  private currentTask = ''

  private isValid = false

  private isLoading = true

  private regionId = this.$route.params.regionID ? +this.$route.params.regionID : projectsModule.regionId
  private projectId = +this.$route.params.projectID

  private projectRegionId: number | null = null

  private form: IProjectRegionRequest = {
    projectId: this.projectId,
    regionId: this.regionId,
    description: '',
    taskCaption: '',
    contacts: [],
    coordinatorId: null,
    chairmanId: null,
    published: false,
    tasks: [{ description: '' }],
  }

  private description = ''

  private projectName = ''
  private regionName = ''

  private get isAddMode() {
    return this.$route.name === 'projects.region.add'
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Проекты',
      titleTemplate: `%s - ${this.projectName} (${this.regionName})`,
    }
  }

  private mounted() {
    this.isAddMode ? this.newProjectRegion() : this.updateProjectRegion()
    this.description = this.form && this.form.description ? this.form.description : ''
  }

  private newProjectRegion() {
    this.isLoading = false

    const projectName = this.projects.find(project => project.id === this.projectId)
    const regionName = this.regions.find(region => region.id === this.regionId)

    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Проекты', path: '../../main' },
        { name: `${projectName ? projectName.name : ''} (${regionName ? regionName.name : ''})`, path: this.$route.path },
      ])
    }, 10)
    this.projectName = projectName ? projectName.name : ''
    this.regionName = regionName ? regionName.name : ''
  }

  private updateProjectRegion() {
    setTimeout(() => {
      commonStore.setBreadcrumbs([
        { name: 'Проекты', path: '../../projects/regions' },
      ])
    }, 10)
    this.projectRegionId = +this.$route.params.projectID
    const { regionId } = this.form

    if (!projectsModule.projectRegion || this.projectRegionId !== projectsModule.currentProjectRegionId) {
      projectsModule.setCurrentProjectRegionId(this.projectRegionId)
      projectsModule.fetchProjectRegion()
        .then((response: IProjectRegionResponse) => {
          this.form = this.transformData(response.project.id, regionId, response)
          commonStore.setBreadcrumbs([
            { name: `${response.project.name} (${response.region.name})`, path: this.$route.path },
          ])
          this.projectName = response.project.name
          this.regionName = response.region.name
        })
        .catch(err => this.notifyError(err))
        .finally(() => this.isLoading = false)
    } else {
      setTimeout(() => {
        commonStore.setBreadcrumbs([
          { name: `${projectsModule.projectRegion ? projectsModule.projectRegion.project.name : ''} (${projectsModule.projectRegion ? projectsModule.projectRegion.region.name : ''})`, path: this.$route.path },
        ])
      }, 10)
      this.projectName = projectsModule.projectRegion.project.name
      this.regionName = projectsModule.projectRegion.region.name
      this.form = this.transformData(projectsModule.projectRegion.project.id, regionId, projectsModule.projectRegion)
      this.isLoading = false
    }
  }

  private transformData(projectId: number, regionId: number, data: IProjectRegionResponse): IProjectRegionRequest {
    return {
      projectId,
      regionId,
      description: data.description,
      taskCaption: data.taskCaption,
      contacts: data.contacts ? data.contacts : [],
      coordinatorId: data.coordinator ? data.coordinator.id : null,
      chairmanId: data.chairman ? data.chairman.id : null,
      published: data.published,
      tasks: data.tasks.length ? data.tasks : [{ description: '' }],
    }
  }

  private addTask() {
    this.form.tasks.push({ description: '' })
  }

  private removeTask(index: number) {
    this.form.tasks = this.form.tasks.filter((task, idx) => index !== idx)
  }

  private addContact() {
    this.form.contacts.push({
      caption: '',
      phone: '',
      email: '',
    })
  }

  private removeContact(index: number) {
    this.form.contacts = this.form.contacts.filter((contact, idx) => index !== idx)
  }

  private addProjectRegion() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          projectsModule.createProjectRegion(this.form)
            .then(() => {
              this.$router.push({ name: 'projects.region' })
              this.notifySuccess('Проект успешно добавлен в регион')
            })
            .catch((err) => this.notifyError(err))
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private changeProjectRegion() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          projectsModule.updateProjectRegion(this.form)
            .then(() => {
              this.$router.push({ name: 'projects.region' })
              this.notifySuccess('Данные обновлены')
            })
            .catch((err) => this.notifyError(err))
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private deleteProjectRegion() {
    projectsModule.deleteProjectRegion()
      .then(() => {
        this.$router.push({ name: 'projects.region' })
        this.notifySuccess('Проект успешно удалён из региона')
      })
      .catch((err) => {
        this.notifyError(err)
      })
  }

  @Watch('form.description')
  private formContentWatch(value) {
    if (value === '' || value === '<p></p>') {
      this.description = ''
    } else {
      this.description = value
    }
  }
}
