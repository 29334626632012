import { RouteConfig } from 'vue-router'

// page
import Interview from '@/views/interview/index.vue'
import InterviewContent from '@/views/interview/content/index.vue'

import auth from './_middleware/auth'


const route: RouteConfig[] = [
  {
    children: [
      {
        component: InterviewContent,
        name: 'interview.content',
        path: 'main',
        meta: { middleware: [auth] },
      },
    ],
    component: Interview,
    name: 'interview',
    path: '/interview',
    redirect: '/interview/main',
  },
]
export default route
