import { AxiosResponse } from 'axios'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import Cookies from 'js-cookie'

import store from '@/store'
import { ILoginForm, IUserType } from '@/store/types'
import api from '@/utils/services/api'
import { authTokenName, authUser } from '@/utils/services/config'

@Module({
  dynamic: true,
  name: 'auth',
  namespaced: true,
  store,
})
class Auth extends VuexModule {
  // ---------------------------- Token ---------------------------- >>

  token = localStorage.getItem('auth-token') || ''

  user: IUserType | null = JSON.parse(localStorage.getItem('auth-user') ?? 'null')

  oAuthUser: IUserType | null = null

  @Mutation
  setToken (payload: string) {
    this.token = payload
    if (process.env.NODE_ENV === 'development') {
      Cookies.set(authTokenName, payload)
    }
  }

  @Mutation
  setUser(payload: IUserType | null) {
    this.user = payload
    if (process.env.NODE_ENV === 'development') {
      Cookies.set(authUser, payload)
    }
  }

  @Mutation
  setUserWithToken (payload: IUserType) {
    this.token = payload.token
    this.user = payload
    this.oAuthUser = payload
  }

  // @Mutation
  // setUserWithoutToken (payload: IUserWithoutToken) {
  //   this.user = {
  //     token: localStorage.getItem('auth-token') as string,
  //     user: { ...payload }
  //   }
  // }
  

  // ---------------------------- Authorization ---------------------------- >>

  @Action({ rawError: true })
  async login (payload: ILoginForm) {
    const { data }: AxiosResponse<IUserType> = await api.post(
      '/login',
      payload,
    )

    await this.setToken(data.token)
    await this.setUser(data)

    return data
  }

  //----- Авторизация через Систему идентификации Партии "Единая Россия"----->

    // получение ссылки через ЕР
    @Action({ rawError: true })
  async getUrlForERLogin(): Promise<string> {
    const { data } = await api.get('/get-admin-auth-url')
  
    return data.url
  }
  
    // отправка полученного кода на бэк
    @Action({ rawError: true })
    async loginWithProfileER (code: string) {
      const { data }: AxiosResponse<IUserType> = await api.post('/oauth-login', { code })
  
      this.setUserWithToken(data)
  
      return data
    }
    //---------Х

  @Action({ rawError: true })
    async logout () {
      await api.post('/logout')

      await this.setToken('')
      await this.setUser(null)
      localStorage.removeItem('auth-token')
      localStorage.removeItem('auth-user')
      if (process.env.NODE_ENV === 'development') {
        Cookies.remove(authTokenName)
        Cookies.remove(authUser)
      }
    }

  @Action({ rawError: true })
  async authError () {
    await this.setToken('')
    await this.setUser(null)
    localStorage.removeItem('auth-token')
    localStorage.removeItem('auth-user')
    if (process.env.NODE_ENV === 'development') {
      Cookies.remove(authTokenName)
      Cookies.remove(authUser)
    }
  }
}


const AuthModule = getModule(Auth)

export default AuthModule
