














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'

// interfaces
import { INewsListResponse } from '@/store/types'

@Component({
  
})
export default class NewsListContent extends Vue {

  @Prop()
  private list!: INewsListResponse[]

  private get news(): INewsListResponse[] {
    return this.list.map(item => ({
      id: item.id,
      caption: item.caption,
      publishedAt: moment(item.publishedAt).format('DD/MM/YYYY'),
    }))
  }

  private handleEdit(id: number) {
    this.$router.push({ name: 'news.item', params: { newsID: id.toString() } })
  }
}
