
































































import Component, { mixins } from 'vue-class-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { MetaInfo } from 'vue-meta'
import { TiptapVuetify, Bold, Paragraph, Link } from 'tiptap-vuetify'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PeopleMixin from '@/mixins/PeopleMixin'
// store
import regionModule from '@/store/modules/regions'
// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import FileUploader from '@/components/_uikit/controls/FileUploader.vue'
// types
import { IRegionResponse, IRegionRequest } from '@/store/types'
import commonStore from '@/store/modules/common'

@Component({
  components: {
    DatePicker,
    TextAreaInput,
    TextInput,
    Select,
    ValidationObserver,
    ValidationProvider,
    FileUploader,
    TiptapVuetify,
  },
})
export default class Region extends mixins (NotifyMixin, PeopleMixin) {

  private extensions = [
    Bold,
    Paragraph,
    Link,
  ]

  private isValid = false

  private isLoading = true

  private isSubmitting = false

  private emblemFileName: string | null = null

  private regionName = ''

  private form: IRegionRequest = {
    quote: '',
    author: '',
    description: '',
    responsibleId: null,
    emblemId: null,
  }

  private id = +this.$route.params.regionID

  private metaInfo (): MetaInfo {
    return {
      title: 'Регионы',
      titleTemplate: `%s - ${this.regionName}`,
    }
  }

  private mounted() {

    commonStore.setBreadcrumbs([
      { name: 'Регионы', path: 'main' },
    ])


    if (!regionModule.region || this.id !== regionModule.currentRegionID) {
      regionModule.setCurrentRegionID(this.id)
      regionModule.fetchRegionById(this.id)
        .then((response) => {
          this.form = this.transformData(response)
          commonStore.setBreadcrumbs([
            { name: response.name, path: this.$route.path },
          ])
          this.regionName = response.name
          this.emblemFileName = response.emblem ? response.emblem.originalName : ''
        })
        .catch((err) => {
          this.notifyError(err)
        })
        .finally(() => this.isLoading = false)


    } else {
      commonStore.setBreadcrumbs([
        { name: regionModule.region.name, path: this.$route.path },
      ])
      this.regionName = regionModule.region.name
      this.form = this.transformData(regionModule.region)
      this.emblemFileName = regionModule.region.emblem ? regionModule.region.emblem.originalName : ''
      this.isLoading = false
    }
  }

  private transformData(region: IRegionResponse): IRegionRequest {
    return {
      quote: region.quote,
      author: region.author,
      description: region.description,
      responsibleId: region.responsible ? region.responsible.id : null,
      emblemId: region.emblem ? region.emblem.id : null,
    }
  }

  private updateRegion() {

    const form: any = this.$refs.form

    if (this.form) {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.isSubmitting = true
            regionModule.updateRegion(this.form)
              .then(() => {
                this.$router.push({ name: 'region.content' })
                this.notifySuccess('Данные региона успешно обновлены!')
              })
              .catch(err => this.notifyError(err))
              .finally(() => this.isSubmitting = false)
          } else {
            this.notifyError('Проверьте введенные данные!')
          }
        })
    }
  }

  private startLoad() {
    this.isSubmitting = true
  }

  private endLoad() {
    this.isSubmitting = false
  }
}
